import React, { memo, useState } from "react";

import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { NextRouter } from "next/router";
import { object } from "yup";

import { Button, ComponentWrapper, PasswordVisibilityToggle, TextFieldForm, WarningUnsavedForm } from "@/components";
import content from "@/content";
import { userPasswordValidator } from "@/utils/validator/user";

interface IRequestEnableTFAForm {
  isSubmitting: boolean;
  onSubmitForm: (values: TEnableTFAForm) => Promise<void>;
  router: NextRouter;
}

const testIdBase = content.containers.requestEnableTfaForm.testId;

const RequestEnableTFAForm = ({ isSubmitting, onSubmitForm, router }: IRequestEnableTFAForm) => {
  const [valuesForm, setValuesForm] = useState<TEnableTFAForm>({
    password: content.validators.userPassword.defaultValue,
  });

  const [showPassword, setShowPassword] = useState(false);

  const validationSchema = object({
    ...userPasswordValidator,
  });

  return (
    <Formik
      initialValues={valuesForm}
      onSubmit={async (data, { resetForm }) => {
        setValuesForm(data);

        resetForm({
          values: data,
        });

        await onSubmitForm(data);
      }}
      validationSchema={validationSchema}
    >
      {({ dirty }) => (
        <Form>
          <WarningUnsavedForm areChangesUnsaved={dirty} />
          <Grid alignItems="stretch" container direction="column" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <ComponentWrapper>
                <TextFieldForm
                  name={content.containers.requestEnableTfaForm.form.password.name}
                  props={{
                    InputProps: {
                      endAdornment: (
                        <PasswordVisibilityToggle
                          changeVisibility={setShowPassword}
                          isPasswordVisible={showPassword}
                          testId={testIdBase}
                        />
                      ),
                    },
                    fullWidth: true,
                    helperText: content.containers.requestEnableTfaForm.form.password.helperText,
                    label: content.containers.requestEnableTfaForm.form.password.label,
                    placeholder: content.containers.requestEnableTfaForm.form.password.placeholder,
                    type: showPassword ? "text" : "password",
                    variant: "outlined",
                  }}
                  testId={testIdBase}
                />
              </ComponentWrapper>
            </Grid>

            <Grid item xs={6}>
              <ComponentWrapper>
                <Button disabled={isSubmitting} fullWidth testId={testIdBase} type="submit" variant="contained">
                  {content.containers.requestEnableTfaForm.form.button.submit.text}
                </Button>
              </ComponentWrapper>

              <Button
                disabled={isSubmitting}
                fullWidth
                onClick={() => router.push(content.containers.requestEnableTfaForm.form.button.redirect.route)}
                size="small"
                testId={testIdBase}
                variant="outlined"
              >
                {content.containers.requestEnableTfaForm.form.button.redirect.text}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default memo(RequestEnableTFAForm);
