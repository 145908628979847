import React, { memo } from "react";

import { default as VisibilityOffMUI } from "@mui/icons-material/VisibilityOff";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.visibilityOff.testId;

const VisibilityOffIcon = ({ testId, ...props }: Icon) => {
  return <VisibilityOffMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(VisibilityOffIcon);
