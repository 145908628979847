import React, { memo } from "react";

import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { useRouter } from "next/router";

import Button from "@/components/Button/Button";
import Typography from "@/components/Typography/Typography";
import content from "@/content";
import { SadFaceEmoji } from "@/icons";
import { CenteredContent } from "@/styles";

const ErrorMessage = styled(Typography)`
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  max-width: 200px;
`;

const testIdBase = content.components.errorContentPage.testId;

const ErrorContentPage = () => {
  const router = useRouter();

  return (
    <CenteredContent>
      <Stack alignItems="center" data-testid={testIdBase} direction="column" justifyContent="center" spacing={2}>
        <SadFaceEmoji height="5rem" testId={`${testIdBase}-emoji`} width="5rem" />
        <ErrorMessage testId={`${testIdBase}-message`}>{content.components.errorContentPage.message}</ErrorMessage>
        <Button
          onClick={() => router.push(content.components.errorContentPage.button.reloadPage)}
          size="large"
          testId={`${testIdBase}-button`}
          variant="contained"
        >
          {content.components.errorContentPage.button.text}
        </Button>
      </Stack>
    </CenteredContent>
  );
};

export { testIdBase };

export default memo(ErrorContentPage);
