import React, { ForwardedRef, forwardRef, memo } from "react";

import styled from "@emotion/styled";
import { Divider, Grid } from "@mui/material";
import Image from "next/image";

import FooterLink from "@/components/FooterLink/FooterLink";
import Typography from "@/components/Typography/Typography";
import { container } from "@/containers/Layout/Layout.styles";
import content from "@/content";

const FooterContainer = styled(container)`
  background-color: #a387d6;
`;

const FooterText = styled(Typography)`
  text-align: center;
  margin-right: auto;
  margin-left: auto;
  padding-right: 1rem;
  padding-left: 1rem;
  color: white;
`;

const DividerFooter = styled(Divider)`
  background-color: white;
  height: 1.2rem;
`;

const testIdBase = content.containers.footer.testId;

const Footer = forwardRef(function FooterComponent(_, ref: ForwardedRef<HTMLDivElement>) {
  return (
    <FooterContainer data-testid={testIdBase} ref={ref}>
      <Image
        alt={content.containers.footer.footerLogoAlt}
        height={50}
        src={content.containers.footer.footerLogo}
        width={150}
      />
      <Grid alignItems="center" container justifyContent="center" spacing={2}>
        {content.containers.footer.footerPages.map(({ pageName, pageRouting }, index) => (
          <React.Fragment key={`${pageName}-${index}`}>
            <FooterLink key={`${pageName}-link`} link={pageRouting} text={pageName} />
            {index + 1 !== content.containers.footer.footerPages.length ? (
              <DividerFooter flexItem key={`${pageName}-divider`} orientation="vertical" />
            ) : null}
          </React.Fragment>
        ))}
      </Grid>

      <FooterText testId={`${testIdBase}-link-text`}>
        Copyright &copy; {content.containers.footer.footerText} - {new Date().getFullYear()}
      </FooterText>
    </FooterContainer>
  );
});

export default memo(Footer);
