import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1025,
      md: 769,
      sm: 481,
      xl: 1201,
      xs: 0,
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: "0.60rem",
          height: 0,
          marginTop: 0,
        },
      },
    },
  },
  palette: {
    background: {
      default: "#FFF",
    },
    primary: {
      light: "#9FA8DA",
      main: "#673AB7",
    },
    secondary: {
      main: "#9FA8DA",
    },
  },
});

export default theme;
