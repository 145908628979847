import AddIcon from "@/icons/AddIcon/AddIcon";
import AppsSharpIcon from "@/icons/AppsSharpIcon/AppsSharpIcon";
import ArrowBackIcon from "@/icons/ArrowBackIcon/ArrowBackIcon";
import BalanceIcon from "@/icons/BalanceIcon/BalanceIcon";
import CheckIcon from "@/icons/CheckIcon/CheckIcon";
import CloseIcon from "@/icons/CloseIcon/CloseIcon";
import ContactSupportIcon from "@/icons/ContactSupportIcon/ContactSupportIcon";
import CookieIcon from "@/icons/CookieIcon/CookieIcon";
import CopyIcon from "@/icons/CopyIcon/CopyIcon";
import DashboardIcon from "@/icons/DashboardIcon/DashboardIcon";
import DeleteIcon from "@/icons/DeleteIcon/DeleteIcon";
import DescriptionIcon from "@/icons/DescriptionIcon/DescriptionIcon";
import EditIcon from "@/icons/EditIcon/EditIcon";
import EmailIcon from "@/icons/EmailIcon/EmailIcon";
import EmailSentEmoji from "@/icons/EmailSentEmoji/EmailSentEmoji";
import ExitToAppIcon from "@/icons/ExitToAppIcon/ExitToAppIcon";
import FolderIcon from "@/icons/FolderIcon/FolderIcon";
import GavelIcon from "@/icons/GavelIcon/GavelIcon";
import GetAppIcon from "@/icons/GetAppIcon/GetAppIcon";
import GridViewIcon from "@/icons/GridViewIcon/GridViewIcon";
import GroupIcon from "@/icons/GroupIcon/GroupIcon";
import GroupsIcon from "@/icons/GroupsIcon/GroupsIcon";
import HappyFaceEmoji from "@/icons/HappyFaceEmoji/HappyFaceEmoji";
import HelpOutlineIcon from "@/icons/HelpOutlineIcon/HelpOutlineIcon";
import HomeIcon from "@/icons/HomeIcon/HomeIcon";
import InfoIcon from "@/icons/InfoIcon/InfoIcon";
import KeyboardArrowDownIcon from "@/icons/KeyboardArrowDownIcon/KeyboardArrowDownIcon";
import ListIcon from "@/icons/ListIcon/ListIcon";
import LockIcon from "@/icons/LockIcon/LockIcon";
import LoginIcon from "@/icons/LoginIcon/LoginIcon";
import LogoutIcon from "@/icons/LogoutIcon/LogoutIcon";
import LoopIcon from "@/icons/LoopIcon/LoopIcon";
import MenuIcon from "@/icons/MenuIcon/MenuIcon";
import NoteAddIcon from "@/icons/NoteAddIcon/NoteAddIcon";
import PagesIcon from "@/icons/PagesIcon/PagesIcon";
import PeopleIcon from "@/icons/PeopleIcon/PeopleIcon";
import PersonAddAltIcon from "@/icons/PersonAddAltIcon/PersonAddAltIcon";
import PersonIcon from "@/icons/PersonIcon/PersonIcon";
import PrivacyTipIcon from "@/icons/PrivacyTipIcon/PrivacyTipIcon";
import QuestionAnswerIcon from "@/icons/QuestionAnswerIcon/QuestionAnswerIcon";
import RefreshIcon from "@/icons/RefreshIcon/RefreshIcon";
import SadFaceEmoji from "@/icons/SadFaceEmoji/SadFaceEmoji";
import SaveIcon from "@/icons/SaveIcon/SaveIcon";
import SettingsIcon from "@/icons/SettingsIcon/SettingsIcon";
import ShareIcon from "@/icons/ShareIcon/ShareIcon";
import SupportIcon from "@/icons/SupportIcon/SupportIcon";
import TeamIcon from "@/icons/TeamIcon/TeamIcon";
import ThumbUpAltIcon from "@/icons/ThumbUpAltIcon/ThumbUpAltIcon";
import UploadIcon from "@/icons/UploadIcon/UploadIcon";
import VisibilityOffIcon from "@/icons/VisibilityOffIcon/VisibilityOffIcon";
import WidgetsIcon from "@/icons/WidgetsIcon/WidgetsIcon";

export {
  AddIcon,
  AppsSharpIcon,
  ArrowBackIcon,
  BalanceIcon,
  CheckIcon,
  CloseIcon,
  ContactSupportIcon,
  CookieIcon,
  CopyIcon,
  DashboardIcon,
  DeleteIcon,
  DescriptionIcon,
  EditIcon,
  EmailIcon,
  EmailSentEmoji,
  ExitToAppIcon,
  FolderIcon,
  GavelIcon,
  GetAppIcon,
  GridViewIcon,
  GroupIcon,
  GroupsIcon,
  HappyFaceEmoji,
  HelpOutlineIcon,
  HomeIcon,
  InfoIcon,
  KeyboardArrowDownIcon,
  ListIcon,
  LockIcon,
  LoginIcon,
  LogoutIcon,
  LoopIcon,
  MenuIcon,
  NoteAddIcon,
  PagesIcon,
  PeopleIcon,
  PersonAddAltIcon,
  PersonIcon,
  PrivacyTipIcon,
  QuestionAnswerIcon,
  RefreshIcon,
  SadFaceEmoji,
  SaveIcon,
  SettingsIcon,
  ShareIcon,
  SupportIcon,
  TeamIcon,
  ThumbUpAltIcon,
  UploadIcon,
  VisibilityOffIcon,
  WidgetsIcon,
};
