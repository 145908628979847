import React, { memo } from "react";

import { default as HelpOutlineIconMUI } from "@mui/icons-material/HelpOutline";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.helpOutline.testId;

const HelpOutline = ({ testId, ...props }: Icon) => {
  return <HelpOutlineIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(HelpOutline);
