import React, { memo } from "react";

import styled from "@emotion/styled";
import { ToastBar, Toaster, toast } from "react-hot-toast";

const CloseButton = styled.button`
  position: relative;
  width: 30px;
  height: 30px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: all 0.3s ease;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 2px;
    height: 14px;
    background-color: #333;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    transition: all 0.3s ease;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &:hover {
    transform: rotate(180deg);
  }
`;

const Notifications = () => {
  return (
    <Toaster
      toastOptions={{
        duration: 86400000,
        style: {
          textAlign: "center",
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== "loading" && <CloseButton onClick={() => toast.dismiss(t.id)}>X</CloseButton>}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

export default memo(Notifications);
