import routing from "@/routing";

const content = {
  components: {
    button: {
      testId: "component-page-title",
    },
    dataTable: {
      testId: "component-datatable",
    },
    dialog: {
      testId: "component-dialog",
    },
    enquiry: {
      testId: "component-enquiry",
    },
    error: {
      testId: "component-error",
    },
    errorContentPage: {
      button: {
        reloadPage: routing.home,
        text: "Go home",
      },
      message: "The page could not be found.",
      testId: "component-error-content-page",
    },
    footerLink: {
      testId: "component-footer-link",
    },
    form: {
      actions: {
        delete: {
          dialog: {
            content: "All form data will be deleted and cannot be recover",
            leftButton: "Cancel",
            rightButton: "Yes",
            testId: "component-form-actions-delete",
            title: "Are you sure you want to delete the selected form?",
            typography: {
              testId: "component-form-actions-delete",
            },
          },
          onDeleteFormAction: {
            loading: "Deleting form...Wait here...",
            success: "Form deleted successfully!",
          },
          tooltip: {
            title: "Delete",
          },
        },
        download: {
          requestFormAsDocBase64: {
            loading: "Requesting form as Document...Wait here...",
            success: "Document has been requested successfully!",
          },
          tooltip: {
            title: {
              completed: "Download",
              incomplete: "Download available only to completed forms",
            },
          },
        },
        edit: {
          tooltip: {
            title: "Edit",
          },
        },
        email: {
          emailForm: {
            dialog: {
              title: "Send form via email",
            },
            form: {
              button: {
                submit: {
                  text: "Add email",
                },
              },
              email: {
                helperText: "Enter an email to send the",
                label: "Email",
                name: "email",
                placeholder: "Enter your email",
              },
              list: {
                title: "Email list",
              },
            },
          },
          notificationMaxEmailNotification:
            "You are reached to the limit of emails to sent the form in a single request",
          onSendEmail: {
            loading: "Sending emails...Wait here...",
            success: "Email has been sent successfully!",
          },
          tooltip: {
            title: {
              completed: "Email",
              incomplete: "Email available only to completed forms",
            },
          },
        },
        share: {
          emailForm: {
            dialog: {
              rightButtonLabel: "Done",
              title: "Share family court case form",
            },
            form: {
              button: {
                submit: {
                  text: "Add email",
                },
              },
              email: {
                helperText: "Enter an email to share the family law court form",
                label: "Add people",
                name: "email",
                placeholder: "",
              },
              list: {
                title: "People with access",
              },
            },
          },
          notificationMaxShareNotification:
            "You are reached to the limit of emails to sent the form in a single request",
          onUpdateShareEmailList: {
            loading: "Updating sharing form... Wait here...",
            success: "Shared email list updated successfully!",
          },
          tooltip: {
            title: "Share",
          },
        },
      },
      saveDraftButton: {
        id: "draftButton",
        text: "Save Draft",
      },
      testId: "component-form",
    },
    iconButtonWrapper: {
      testId: "component-icon-button-wrapper",
    },
    link: {
      testId: "component-link",
    },
    loaderSpinner: {
      testId: "component-loader-spinner",
    },
    pageTitle: {
      testId: "component-page-title",
    },
    passwordVisibilityToggle: {
      passwordNotVisible: "Show password",
      passwordVisible: "Hide password",
      testId: "component-password-visibility-toggle",
    },
    serviceCard: {
      testId: "component-service-card",
    },
    textField: {
      testId: "component-text-field",
    },
    textFieldForm: {
      testId: "component-text-field-form",
    },
    typography: {
      testId: "component-typography",
    },
  },
  containers: {
    contactUsForm: {
      button: {
        submit: {
          text: "Submit",
        },
      },
      form: {
        description: {
          helperText: "Enter description to contact you. Maximum character limit is 2048.",
          label: "Description",
          name: "description",
          placeholder: "Enter a description",
        },
        email: {
          helperText: "Enter an email to be contacted",
          label: "Email",
          name: "email",
          placeholder: "Enter an email",
        },
        errorOnRequest: "The form was not submitted successfully. Please, contact us via email.",
        onSubmitted: "Submitted successfully. Check your inbox to view a copy of the details submitted.",
        onSubmitting: "Submitting the information... Wait here...",
        title: {
          helperText: "Enter a title",
          label: "Title",
          name: "title",
          placeholder: "Enter a title",
        },
      },
      testId: "containers-contact-us-form",
      title: "Contact us",
    },
    disableTfaForm: {
      form: {
        button: {
          redirect: {
            route: routing.user.profile,
            text: "Cancel",
          },
          submit: {
            text: "Finish setup",
          },
        },
        otp: {
          helperText: "Please enter the first one-time password to finish the setup",
          label: "One-time Password",
          name: "otp",
        },
      },
      onDisableTfa: {
        redirect: {
          route: routing.user.profile,
        },
      },
      testId: "container-enable-tfa-form",
    },
    enableTfaForm: {
      form: {
        button: {
          redirect: {
            route: routing.user.profile,
            text: "Cancel",
          },
          submit: {
            text: "Finish setup",
          },
        },
        otp: {
          helperText: "Please enter the first one-time password to finish the setup",
          label: "One-time Password",
          name: "otp",
        },
      },
      onEnableTfa: {
        redirect: {
          route: routing.user.profile,
        },
      },
      testId: "container-enable-tfa-form",
    },
    errorContainer: {
      errorButtonText: "Refresh",
      errorText: "Something went wrong",
      testId: "container-error",
    },
    feedback: {
      dialog: {
        rightButton: {
          label: "Close",
        },
        title: "Rate us!",
      },
      form: {
        comment: {
          helperText: "Enter a short comment about your experience",
          label: "Short Comment",
          name: "comment",
          placeholder: "Enter a short comment",
        },
        submit: {
          text: "Send",
        },
      },
      onSubmitted: "Thank you for your feedback!",
      rating: {
        name: "rating",
      },
      testId: "container-feedback",
    },
    firstTimeVisit: {
      dialog: {
        content:
          "Your browser might be tracking this visit if you are not browsing on a private mode. See our help page for more information.",
        leftButton: {
          label: "Close",
        },
        rightButton: {
          label: "Visit help page",
        },
        title: "Welcome to Family Law!",
      },

      testId: "container-first-time-visit",
    },
    footer: {
      footerHref: "#",
      footerLogo: "/assets/footer.png",
      footerLogoAlt: "footer",
      footerPages: [
        {
          pageName: "Terms",
          pageRouting: routing.content.terms,
        },
        {
          pageName: "Privacy Policy",
          pageRouting: routing.content.privacy,
        },
        {
          pageName: "Cookies Policy",
          pageRouting: routing.content.cookies,
        },
        {
          pageName: "Contact us",
          pageRouting: routing.content.contactUs,
        },
      ],
      footerText: "Family Law",
      testId: "container-footer",
    },
    layout: {
      headDescription: "Family Law - LSBU",
      headTitle: "Family Law",
      testId: "container-layout",
    },
    navigationBar: {
      testId: "container-navigation-bar",
    },
    navigationMenu: {
      testId: "container-navigation-menu",
    },
    navigationMenuItem: {
      testId: "container-navigation-menu-items",
    },
    navigationMenuList: {
      testId: "container-navigation-menu-list",
    },
    requestEnableTfaForm: {
      form: {
        button: {
          redirect: {
            route: routing.user.profile,
            text: "Go back",
          },
          submit: {
            text: "Generate One-Time Password",
          },
        },
        password: {
          helperText: "Enter your new password",
          label: "Password",
          name: "password",
          placeholder: "Enter your password",
        },
      },
      testId: "container-request-enable-tfa-form",
    },
  },
  icons: {
    add: {
      testId: "",
    },
    arrowBack: {
      testId: "",
    },
    button: {
      testId: "",
    },
    check: {
      testId: "",
    },
    close: {
      testId: "",
    },
    contactSupport: {
      testId: "",
    },
    cookie: {
      testId: "",
    },
    copy: {
      testId: "",
    },
    dashboard: {
      testId: "",
    },
    delete: {
      testId: "",
    },
    description: {
      testId: "",
    },
    edit: {
      testId: "",
    },
    email: {
      testId: "",
    },
    emailSent: {
      testId: "",
    },
    exitToApp: {
      testId: "",
    },
    folderIcon: {
      testId: "",
    },
    gavel: {
      testId: "",
    },
    getApp: {
      testId: "",
    },
    gridView: {
      testId: "",
    },
    group: {
      testId: "",
    },
    groups: {
      testId: "",
    },
    happyFaceEmoji: {
      testId: "",
    },
    helpOutline: {
      testId: "",
    },
    home: {
      testId: "",
    },
    info: {
      testId: "",
    },
    keyboardArrowDown: {
      testId: "",
    },
    list: {
      testId: "",
    },
    lock: {
      testId: "",
    },
    login: {
      testId: "",
    },
    logout: {
      testId: "",
    },
    loop: {
      testId: "",
    },
    menu: {
      testId: "",
    },
    noteAdd: {
      testId: "",
    },
    pages: {
      testId: "",
    },
    people: {
      testId: "",
    },
    person: {
      testId: "",
    },
    personAddAlt: {
      testId: "",
    },
    privacyTip: {
      testId: "",
    },
    questionAnswer: {
      testId: "",
    },
    refresh: {
      testId: "",
    },
    sadFaceEmoji: {
      testId: "",
    },
    save: {
      testId: "",
    },
    settings: {
      testId: "",
    },
    share: {
      testId: "",
    },
    support: {
      testId: "",
    },
    team: {
      testId: "",
    },
    thumbUpAlt: {
      testId: "",
    },
    upload: {
      testId: "",
    },
    visibilityOff: {
      testId: "",
    },
    widgets: {
      testId: "",
    },
  },
  pages: {
    ServerError: {
      errorButtonText: "Go Home",
      errorText: "Something went wrong",
      onReloadActionPageRouting: routing.home,
      testId: "page-server-error-500",
    },
    auth: {
      error: {
        errorButtonText: "Go Home",
        errorText: "Something went wrong",
        onReloadActionPageRouting: routing.home,
        testId: "page-auth-error",
      },
      forgotPassword: {
        form: {
          button: {
            submit: {
              text: "Recover password",
            },
          },
          email: {
            helperText: "Enter your email to recover the password",
            label: "Email",
            name: "email",
            placeholder: "Enter your email",
          },
        },
        testId: "page-auth-forgot-password",
        title: "Recover Password",
      },
      resetPassword: {
        form: {
          button: {
            submit: {
              text: "Recover password",
            },
          },
          password: {
            helperText: "Use 8 or more characters with a mix of letters, numbers & symbols",
            label: "Password",
            name: "password",
            placeholder: "Enter your password",
          },
          passwordConfirmation: {
            helperText: "Both password must be the same",
            label: "Password",
            name: "passwordConfirmation",
            placeholder: "Enter your password",
          },
        },
        testId: "page-auth-reset-password",
        title: "Reset Password",
      },
      signIn: {
        form: {
          button: {
            redirect: {
              route: routing.auth.forgotPassword,
              text: "Forgot Password?",
            },
            submit: {
              text: "Submit",
            },
          },
          email: {
            helperText: "Enter your email of your account",
            label: "Email",
            name: "email",
            placeholder: "Enter your email",
          },
          otp: {
            helperText: "Please enter your one-time password",
            label: "One-time Password",
            name: "otp",
          },
          password: {
            helperText: "Use 8 or more characters with a mix of letters, numbers & symbols",
            label: "Password",
            name: "password",
            placeholder: "Enter your password",
          },
        },
        testId: "page-auth-sign-in",
        title: "Sign In",
      },
      signUp: {
        form: {
          button: {
            redirectRegister: {
              route: routing.auth.signIn,
              text: "Do you have an account?",
            },
            redirectRequestVerification: {
              route: routing.auth.verify,
              text: "Request verification",
            },
            submit: {
              text: "Register",
            },
          },
          checkTaC: {
            error: "Accept the terms and conditions in order to create an account",
            label: "Terms and Conditions",
            name: "checkTaC",
          },
          email: {
            helperText:
              "Please offer a safe email address which will be used to set up your registration and to receive communications from us, such as your completed forms",
            label: "Email",
            name: "email",
            placeholder: "Enter your email",
          },
          password: {
            helperText: "Use 8 or more characters with a mix of letters, numbers & symbols",
            label: "Password",
            name: "password",
            placeholder: "Enter your password",
          },
          passwordConfirmation: {
            helperText: "Both password must be the same",
            label: "Password",
            name: "passwordConfirmation",
            placeholder: "Enter your password",
          },
        },
        testId: "page-auth-sign-up",
        title: "Register",
      },
      verify: {
        form: {
          button: {
            submit: {
              submittingText: (number: number) => `Wait ${number} seconds to request another link`,
              text: "Request verification link",
            },
          },
          email: {
            helperText: "Enter your email to recover the password",
            label: "Email",
            name: "email",
            placeholder: "Enter your email",
          },
        },
        testId: "page-auth-verify-account",
        title: "Account Verification",
      },
    },
    content: {
      contactUs: {
        replacementComponent: "{contactUsForm}",
        replacementHtmlComponent: '<div id="contactUsFormReplacementComponent"></div>',
        replacementHtmlComponentId: "contactUsFormReplacementComponent",
        testId: "page-content-contact-us",
        title: "Contact us",
      },
    },
    enquiry: {
      create: {
        messageOnLoading: "Creating enquiry... Wait here...",
        messageOnSubmit: "Enquiry submitted successfully",
        modal: {
          messageModalSubmittedButton: "Ok",
          testId: "page-enquiry-create-modal-submitted",
          title: "Enquiry submitted successfully",
          typography: {
            messageModalSubmitted:
              "Thank you for your enquiry. We will aim to response to you within 5 working days and we will contact you on the email address you provided. You will receive a copy of your enquiry by email. Please check your junk/spam folder if you can’t find it in your inbox.",
            testId: "page-enquiry-create-modal-submitted-typography",
          },
        },
      },
    },
    form: {
      create: {
        anonymous: {
          dialog: {
            emailForm: {
              form: {
                button: {
                  submit: {
                    text: "Continue",
                  },
                },
                email: {
                  helperText: "Enter an email to send the form",
                  label: "Email",
                  name: "email",
                  placeholder: "Enter your email",
                },
              },
              rightButton: {
                label: "Cancel",
              },
              title: "Creating form as an anonymous user",
            },
            success: {
              content:
                "Form has been created successfully and you will receive an email with the ID and the form as a document",
              leftButton: {
                label: "Go Home",
              },
              rightButton: {
                label: "Close",
              },
              title: "Form created, well done!",
            },
          },
          messageOnLoading: "Creating... Wait here...",
          messageOnSubmit: "Form created successfully!",
          testId: "page-form-create-anonymous",
        },
        authenticated: {
          messageOnLoading: "Creating... Wait here...",
          messageOnSubmit: "Form created successfully!",
          onSaveDraftAnswers: {
            loading: "Saving form...Just wait a little bit...",
            success: "Form saved successfully as draft!",
          },
          testId: "page-form-create",
        },
      },
      edit: {
        errorOnRequest: "The form is not available or you do not access.",
        messageOnLoading: "Saving... Wait here...",
        messageOnSubmit: "Form saved successfully!",
        onSaveAnswers: {
          loading: "Saving form...Just wait a little bit...",
          success: "Form saved successfully!",
        },
        testId: "page-form-edit",
      },
      list: {
        mine: {
          table: {
            empty: "Sorry, you do not have any forms created.",
          },
          testId: "page-form-list-mine",
          title: "Forms",
        },
        shared: {
          table: {
            empty: "Sorry, you do not have any forms shared with you yet.",
          },
          testId: "page-form-list-shared",
          title: "Shared Forms",
        },
      },
      recover: {
        form: {
          button: {
            submit: {
              text: "Recover form",
            },
          },
          id: {
            helperText: "Enter the Fmro ID sent via email.",
            label: "Form ID",
            name: "formId",
            placeholder: "Form ID",
          },
        },
        onRequestFormRecover:
          "The form has been requested successfully if the form id matches and it will be sent to the original email during its creation.",
        testId: "page-form-recover",
        title: "Recover Form",
      },
      viewOnly: {
        errorOnRequest: "The token has expired or is not longer valid.",
        modal: {
          content: "You have read-only access and limited to 7 days. To edit the form, please, click on register.",
          leftButton: "Close",
          rightButton: "Register",
          title: "Family Law Court Read Only",
        },
        testId: "page-form-view",
      },
    },
    home: {
      testId: "page-home",
    },
    notFound: {
      errorButtonText: "Go Home",
      errorText: "Page has not been found",
      onReloadActionPageRouting: routing.home,
      testId: "page-not-found-404",
    },
    services: {
      testId: "page-services",
    },
    unauthorized: {
      button: "Go home",
      onReloadActionPageRouting: routing.home,
      testId: "page-unauthorized",
      text: "Looks like you do not have permission to visit this page...",
    },
    user: {
      closeAccount: {
        dialogConfirmation: {
          content: "All your data will be deleted and will not be able to recover it",
          leftButton: "Cancel",
          rightButton: "Yes",
          title: "Are you sure you want to close your account?",
        },
        form: {
          button: {
            redirect: {
              route: routing.user.profile,
              text: "Go back",
            },
            submit: {
              text: "Close Account",
            },
          },
          otp: {
            helperText: "Please enter your one-time password",
            label: "One-time Password",
            name: "otp",
          },
          password: {
            helperText: "Enter your current password",
            label: "Password",
            name: "password",
            placeholder: "Enter your password",
          },
        },
        testId: "page-user-close-account",
        title: "Close Account",
      },
      disableTfa: {
        testId: "page-user-disable-tfa",
        title: "Disable Two-Factor Authentication",
      },
      enableTfa: {
        testId: "page-user-enable-tfa",
        title: "Enable Two-Factor Authentication",
      },
      profile: {
        redirectPages: (userOtpEnabled: boolean | undefined) => [
          { redirect: routing.user.updateEmail, title: "Change Email" },
          { redirect: routing.user.updatePassword, title: "Change Password" },
          {
            redirect: !userOtpEnabled ? routing.user.enableTfa : routing.user.disableTfa,
            title: `${!userOtpEnabled ? "Enable" : "Disable"} Two-Factor Authentication`,
          },
          { redirect: routing.user.closeAccount, title: "Close Account" },
        ],
        testId: "page-user-profile",
        title: "Profile",
      },
      updateEmail: {
        form: {
          button: {
            redirect: {
              route: routing.user.profile,
              text: "Go back",
            },
            submit: {
              text: "Update",
            },
          },
          email: {
            helperText: "Enter your email",
            label: "Email",
            name: "email",
            placeholder: "Enter your email",
          },
          emailNew: {
            helperText: "Enter your new email",
            label: "Email",
            name: "emailNew",
            placeholder: "Enter your new email",
          },
          otp: {
            helperText: "Please enter the first one-time password",
            label: "One-time Password",
            name: "otp",
          },
          password: {
            helperText: "Enter your current password",
            label: "Password",
            name: "password",
            placeholder: "Enter your password",
          },
        },
        testId: "page-user-update-email",
        title: "Update Email",
      },
      updatePassword: {
        form: {
          button: {
            redirect: {
              route: routing.user.profile,
              text: "Go back",
            },
            submit: {
              text: "Update Password",
            },
          },
          otp: {
            helperText: "Please enter the first one-time password",
            label: "One-time Password",
            name: "otp",
          },
          password: {
            helperText: "Enter your new password",
            label: "Password",
            name: "password",
            placeholder: "Enter your password",
          },
          passwordConfirmation: {
            helperText: "Enter again your new password",
            label: "Password",
            name: "passwordConfirmation",
            placeholder: "Enter your password",
          },
          passwordCurrent: {
            helperText: "Enter your password",
            label: "Current Password",
            name: "passwordCurrent",
            placeholder: "Enter your password",
          },
        },
        testId: "page-user-update-password",
        title: "Update Password",
      },
    },
  },
  validators: {
    commentAction: {
      defaultValue: "",
      max: "You cannot exceed more than 255 characters",
    },
    contactUsDescription: {
      defaultValue: "",
      maxCharacters: "Description cannot be more than 2048 characters long",
      required: "Description is required",
    },
    contactUsEmail: {
      defaultValue: "",
      invalid: "Invalid email address",
    },
    contactUsTitle: {
      defaultValue: "",
      maxCharacters: "Title cannot be more than 100 characters long",
      required: "Title is required",
    },
    formEmailAction: {
      defaultValue: "",
      invalid: "Invalid email address",
      max: "You cannot exceed more than 100 characters",
      maxCount: 100,
      notOneOf: "Email address must not be repeated",
      required: "Enter an email",
    },
    formRecover: {
      defaultValue: "",
      required: "Enter the form ID",
    },
    formShareAction: {
      defaultValue: "",
      invalid: "Invalid email address",
      max: "You cannot exceed more than 100 characters",
      maxCount: 100,
      notOneOf: "Email address must not be repeated",
      required: "Enter an email",
      sameUserEmail: "You cannot share with yourself",
    },
    ratingAction: {
      defaultValue: 3,
      max: "Enter a maximum of 5 stars",
      required: "Enter a rating",
    },
    userEmail: {
      defaultValue: "",
      invalid: "Invalid email address",
      required: "Enter an email",
    },
    userEmailUpdate: {
      defaultValue: "",
      invalid: "Invalid email address",
      notOneOf: "New email address must be different",
      required: "Enter an email",
    },
    userOtp: {
      defaultValue: -1,
      regex: /^\d+$/,
      regexInvalid: "Only numbers are allowed",
      required: "One-time password is required",
      test: "One-time password must be 6 digits",
    },
    userPassword: {
      defaultValue: "",
      regex: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\s).*$/,
      regexInvalid: "Use 8 or more characters with a mix of lowercase and uppercase, numbers & symbols",
      required: "Enter your password",
    },
    userPasswordConfirmation: {
      defaultValue: "",
      ref: "Passwords must match",
      required: "Password confirmation is required",
    },
    userPasswordCurrent: {
      defaultValue: "",
      regex: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\s).*$/,
      regexInvalid: "Use 8 or more characters with a mix of lowercase and uppercase, numbers & symbols",
      required: "Enter your current password",
    },
    userPasswordNew: {
      defaultValue: "",
      notOneOf: "The new password cannot be the same as your current password",
      regex: /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\s).*$/,
      regexInvalid: "Use 8 or more characters with a mix of lowercase and uppercase, numbers & symbols",
      required: "Enter your password",
    },
    userSignUpCheckTaC: {
      defaultValue: false,
      oneOf: "Accept the terms and conditions in order to create an account",
    },
    userSupportOptions: {
      defaultValue: "",
      oneOf: "You must choose one option from the dropdown",
    },
  },
};

export default content;
