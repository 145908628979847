import React, { memo } from "react";

import styled from "@emotion/styled";

import Link from "@/components/Link/Link";
import Typography from "@/components/Typography/Typography";
import content from "@/content";

interface IFooterLink {
  link: string;
  text: string;
}

const FooterLinkBase = styled(Typography)`
  color: white;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
`;

const FooterWrapper = styled.div``;

const testIdBase = content.components.footerLink.testId;

const FooterLink = ({ link, text }: IFooterLink) => {
  return (
    <FooterWrapper date-testid={testIdBase}>
      <Link color="inherit" href={link} rel="noopener noreferrer" testId={`${testIdBase}-link`} underline="none">
        <FooterLinkBase testId={testIdBase}>{text}</FooterLinkBase>
      </Link>
    </FooterWrapper>
  );
};

export default memo(FooterLink);
