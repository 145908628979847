import React, { memo } from "react";

import { default as HomeIconMUI } from "@mui/icons-material/Home";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.home.testId;

const HomeIcon = ({ testId, ...props }: Icon) => {
  return <HomeIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(HomeIcon);
