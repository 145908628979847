import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getUserData } from "@/request/user";
import { Role } from "@/utils/permissions";

const initialUserState = {
  email: undefined,
  id: undefined,
  isAuthenticated: false,
  roleId: undefined,
  roleName: Role.UNAUTHENTICATED,
  tfa: undefined,
};

export const getUserDataAsyncThunk = createAsyncThunk("authentication/fetchUserData", async () => {
  try {
    const response = await getUserData();

    return response;
  } catch {
    return null;
  }
});

export interface AuthenticationContext {
  loading: boolean;
  user: TUser;
}

const initialState: AuthenticationContext = {
  loading: true,
  user: {
    email: undefined,
    id: undefined,
    isAuthenticated: false,
    roleId: undefined,
    roleName: Role.UNAUTHENTICATED,
    tfa: undefined,
  },
};

export const authenticationSlide = createSlice({
  extraReducers: (builder) => {
    builder.addCase(getUserDataAsyncThunk.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserDataAsyncThunk.fulfilled, (state, action) => {
      state.loading = false;

      if (action.payload) {
        state.user = action.payload;
      } else {
        state.user = initialUserState;
      }
    });

    builder.addCase(getUserDataAsyncThunk.rejected, (state) => {
      state.loading = false;

      state.user = initialUserState;
    });
  },
  initialState,
  name: "authentication",
  reducers: {
    updateAuthentication: (state, action: PayloadAction<TUser>) => {
      state.user = action.payload;
    },
  },
});

export const { updateAuthentication } = authenticationSlide.actions;

export default authenticationSlide.reducer;
