import React, { memo } from "react";

import Error from "@/components/Error/Error";
import content from "@/content";

const testId = content.containers.errorContainer.testId;

const ErrorContainer = () => {
  return (
    <Error
      errorButtonText={content.containers.errorContainer.errorButtonText}
      errorText={content.containers.errorContainer.errorText}
      onReloadAction={() => location.reload()}
      testId={testId}
    />
  );
};

export default memo(ErrorContainer);
