const routing = {
  auth: {
    error: "/auth/error",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    signIn: "/auth/signin",
    signOut: "/auth/signout",
    signUp: "/auth/signup",
    verify: "/auth/verify",
  },
  contactSupport: "/contact-support",
  content: {
    aboutUs: "/content/about-us",
    contactUs: "/content/contact-us",
    cookies: "/content/cookies",
    faq: "/content/faq",
    help: "/content/help",
    legalAdviceDropInClinic: "/content/legal-advice-drop-in-clinic",
    privacy: "/content/privacy",
    support: "/content/support",
    terms: "/content/terms",
    windrushJusticeClinic: "/content/windrush-justice-clinic",
  },
  enquiry: {
    create: "/enquiry/create",
  },
  form: {
    create: {
      authenticated: "/form/create",
      unauthenticated: "/form/create/anonymous",
    },
    edit: "/form/edit",
    list: "/form/list/mine",
    sharedWithMe: "/form/list/shared",
  },
  home: "/",
  notFound: "404",
  recoverFormAnonymously: "/form/recover",
  services: "/services",
  unauthorized: "/unauthorized",
  user: {
    closeAccount: "/user/close-account",
    disableTfa: "/user/disable-tfa",
    enableTfa: "/user/enable-tfa",
    profile: "/user/profile",
    updateEmail: "/user/update-email",
    updatePassword: "/user/update-password",
  },
};

export default routing;
