import React, { memo } from "react";

import { default as PersonAddAltIconMUI } from "@mui/icons-material/PersonAddAlt";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.personAddAlt.testId;

const PersonAddAltIcon = ({ testId, ...props }: Icon) => {
  return <PersonAddAltIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(PersonAddAltIcon);
