import { AuthenticationData, AuthenticationStorage, authentication, createDirectus, rest } from "@directus/sdk";

export const directusAuthLocalStorage = () => {
  const authKey = "auth_token";

  const get = async () => {
    const data = window.localStorage.getItem(authKey);
    if (data) {
      return JSON.parse(data);
    }

    return null;
  };

  const set = async (value: AuthenticationData | null) => {
    if (!value) {
      return window.localStorage.removeItem(authKey);
    }

    return window.localStorage.setItem(authKey, JSON.stringify(value));
  };

  const remove = async () => {
    window.localStorage.removeItem(authKey);
  };

  const directusAuthLocalStorageObject = { get, set } as AuthenticationStorage;

  return {
    ...directusAuthLocalStorageObject,
    remove,
  };
};

const directus = createDirectus(process.env.NEXT_PUBLIC_BACKEND_ENDPOINT)
  .with(
    authentication("json", {
      storage: directusAuthLocalStorage(),
    })
  )
  .with(rest());

export default directus;
