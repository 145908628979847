import { createItem } from "@directus/sdk";

import directus from "@/request/client/directus";

const submitFeedback = async (rating: number, review: string) => {
  try {
    await directus.request(
      createItem("feedback", {
        rating,
        review,
      })
    );
  } catch (error) {
    throw new Error("Something went wrong submitting the feedback");
  }
};

export { submitFeedback };
