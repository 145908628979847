import React, { ElementType, memo } from "react";

import styled from "@emotion/styled";
import { IconButton, IconButtonProps } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

import content from "@/content";

const testIdBase = content.components.iconButtonWrapper.testId;

interface IIconButtonWrapper extends IconButtonProps {
  component?: ElementType<unknown>;
  testId: string;
  tooltip?: string | undefined;
}

const StyledIconButton = styled(IconButton)``;

const IconButtonWrapper = ({ children, testId, tooltip, ...props }: IIconButtonWrapper) => {
  if (!tooltip) {
    return (
      <StyledIconButton data-testid={`${testIdBase}-${testId}`} {...props}>
        {children}
      </StyledIconButton>
    );
  }

  return (
    <Tooltip disableInteractive title={tooltip}>
      <StyledIconButton data-testid={`${testIdBase}-${testId}`} {...props}>
        {children}
      </StyledIconButton>
    </Tooltip>
  );
};

IconButtonWrapper.defaultProps = { component: undefined, tooltip: undefined };

export default memo(IconButtonWrapper);
