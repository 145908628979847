import React, { memo } from "react";

import styled from "@emotion/styled";
import { TypographyProps } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Typography from "@/components/Typography/Typography";
import Wrapper from "@/components/Wrapper/Wrapper";
import content from "@/content";

const testIdBase = content.components.pageTitle.testId;

interface TitleProps {
  colorDark: string;
  colorLight: string;
}

interface TitleProps {
  colorDark: string;
  colorLight: string;
  fontWeight?: number;
  textAlignment?: string;
}

const getLinearGradientTitle = (colorLight: string, colorDark: string) => {
  if (typeof colorLight === "string" && typeof colorDark == "string") {
    return `${colorLight}, ${colorDark}`;
  }

  return "";
};

const Title = styled.div<TitleProps>`
  font-weight: ${(props) => props.fontWeight || "800"};
  background: -webkit-linear-gradient(${(props) => getLinearGradientTitle(props.colorLight, props.colorDark)});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: ${(props) => props.textAlignment || "inherit"};
`;

interface IPageTitle extends TypographyProps {
  fontColor?: string;
  fontWeight?: number;
  margin?: string | undefined;
  testId: string;
  textAlignment?: string;
  title: string;
}

const PageTitle = ({ fontColor, fontWeight, margin, testId, textAlignment, title, variant }: IPageTitle) => {
  const theme = useTheme();

  return (
    <Wrapper data-testid={`${testIdBase}-${testId}`} margin={margin}>
      <Typography testId={`${testIdBase}-${testId}`} variant={variant}>
        <Title
          colorDark={fontColor || theme.palette.primary.dark}
          colorLight={fontColor || theme.palette.primary.light}
          fontWeight={fontWeight}
          textAlignment={textAlignment}
        >
          {title}
        </Title>
      </Typography>
    </Wrapper>
  );
};

PageTitle.defaultProps = { fontColor: "#9FA8DA", margin: undefined };

export default memo(PageTitle);
