import React, { memo } from "react";

import { useField } from "formik";

import TextField, { ITextField } from "@/components/TextField/TextField";
import content from "@/content";

const testIdBase = content.components.textFieldForm.testId;

interface ITextFieldForm extends ITextField {
  name: string;
}

const TextFieldForm = ({ name, props, testId }: ITextFieldForm) => {
  const [field, meta] = useField(name);

  if (props.type === "number" || props.type === "text") {
    field.value = !field.value && field.value !== 0 ? "" : field.value;
  }

  if (meta && meta.touched && meta.error) {
    props.error = true;
    props.helperText = meta.error;
  }

  return <TextField props={{ ...field, ...props }} testId={`${testIdBase}-${testId}`} />;
};

export default memo(TextFieldForm);
