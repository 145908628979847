import React, { memo } from "react";

import { default as LoopIconMUI } from "@mui/icons-material/Loop";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.loop.testId;

const LoopIcon = ({ testId, ...props }: Icon) => {
  return <LoopIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(LoopIcon);
