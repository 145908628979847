import React, { memo } from "react";

import { default as GetAppIconMUI } from "@mui/icons-material/GetApp";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.widgets.testId;

const GetAppIcon = ({ testId, ...props }: Icon) => {
  return <GetAppIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(GetAppIcon);
