import React, { memo } from "react";

import styled from "@emotion/styled";
import { Box } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useRouter } from "next/router";

import Typography from "@/components/Typography/Typography";
import NavigationMenu from "@/containers/NavigationMenu/NavigationMenu";
import content from "@/content";
import routing from "@/routing";

const testIdBase = content.containers.navigationBar.testId;

const AppBarStyle = styled(AppBar)`
  align-items: left;
`;

const Logo = styled(Typography)`
  text-decoration: "none";
  cursor: pointer;
`;

const NavigationBar = () => {
  const router = useRouter();

  return (
    <AppBarStyle data-testid={testIdBase} position="static">
      <Toolbar variant="dense">
        <Logo onClick={() => router.push(routing.home)} testId={testIdBase} variant="h4">
          <Box fontWeight="fontWeightBold">Themis</Box>
        </Logo>
      </Toolbar>

      <NavigationMenu />
    </AppBarStyle>
  );
};

export default memo(NavigationBar);
