import React, { memo } from "react";

import { default as AddIconMUI } from "@mui/icons-material/Add";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.add.testId;

const AddIcon = ({ testId, ...props }: Icon) => {
  return <AddIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(AddIcon);
