import React, { memo } from "react";

import { default as ListIconMUI } from "@mui/icons-material/List";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.list.testId;

const ListIcon = ({ testId, ...props }: Icon) => {
  return <ListIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(ListIcon);
