export enum Role {
  ADMIN = "ADMIN",
  ALL = "ALL",
  AUTHENTICATED = "AUTHENTICATED",
  LITIGANT = "LITIGANT",
  UNAUTHENTICATED = "UNAUTHENTICATED",
}

const isRoleScopeAuthorized = (scope: Role[], role: Role): boolean => {
  if (scope.includes(Role.ALL) || (scope.includes(Role.AUTHENTICATED) && role !== Role.UNAUTHENTICATED)) {
    return true;
  }

  return scope.includes(role);
};

const isSingleRoleScopeAuthorized = (scope: Role, role: Role): boolean => {
  if (scope === Role.ALL || (scope === Role.AUTHENTICATED && role !== Role.UNAUTHENTICATED)) {
    return true;
  }

  return scope === role;
};

const isScopeAuthorizedByRoles = (scope: Role[] | Role, role: Role) => {
  if (typeof scope === "string") {
    return isSingleRoleScopeAuthorized(scope, role);
  }

  return isRoleScopeAuthorized(scope, role);
};

export const isScopeAuthorized = (scope: Role[] | Role | undefined, role: Role | undefined): boolean => {
  if (!scope) {
    return true;
  }

  if (!role) {
    return false;
  }

  if (isScopeAuthorizedByRoles(scope, role)) {
    return true;
  }

  return false;
};
