import {
  deleteUser,
  disableTwoFactor,
  enableTwoFactor,
  generateTwoFactorSecret,
  passwordRequest,
  passwordReset,
  readMe,
  readRole,
  updateMe,
} from "@directus/sdk";
import { AxiosError } from "axios";

import directus, { directusAuthLocalStorage } from "@/request/client/directus";
import Rest from "@/request/client/rest";
import routing from "@/routing";
import { blankNotification, errorNotification, loadingNotification, successNotification } from "@/utils/notifications";
import { Role } from "@/utils/permissions";

const getUserRoleNameByRoleId = async (roleId: string): Promise<Role | null> => {
  try {
    const data = await directus.request(
      readRole(roleId, {
        fields: ["name"],
      })
    );

    if (data) {
      const userData = data as { name: string };

      return userData.name.toUpperCase() as Role;
    }

    return null;
  } catch {
    return null;
  }
};

const getUserData = async (): Promise<TUser | null> => {
  try {
    const userData = await directus.request(
      readMe({
        fields: ["email", "id", "role", "tfa_secret"],
      })
    );

    const roleName = await getUserRoleNameByRoleId(userData.role);

    return {
      email: userData.email || "",
      id: userData.id,
      isAuthenticated: true,
      roleId: userData.role,
      roleName: roleName || Role.UNAUTHENTICATED,
      tfa: !!userData.tfa_secret,
    };
  } catch {
    directusAuthLocalStorage().remove();

    return null;
  }
};

const getErrorMessageOnCloseAccount = (message: string) => {
  if (message.includes("Invalid user credentials")) {
    return "The password is invalid";
  }

  return "Something went wrong";
};

const getErrorMessageOnUpdateEmail = (message: string) => {
  if (message.includes("has to be unique")) {
    return "The email entered is already used";
  }

  return "Unknown error";
};

const getErrorMessageOnAuthentication = (message: string) => {
  if (message.includes("Invalid user credentials")) {
    return "Incorrect password or one-time password";
  }

  return "Unknown error";
};

const getErrorMessageOnSignIn = (message: string) => {
  if (message.includes("Invalid user credentials")) {
    return "The email or password are invalid, or the account has not been verified.";
  }

  if (message.includes("otp") && message.includes("invalid")) {
    return "Wrong one-time password";
  }

  return "The email or password are invalid, or the account has not been verified.";
};

const getErrorMessageOnEnableTfa = (message: string) => {
  if (message.includes("otp") && message.includes("invalid")) {
    return "Wrong one-time password";
  }

  return "Unknown error";
};

const getErrorMessageOnDisableTfa = (message: string) => {
  if (message.includes("otp") && message.includes("invalid")) {
    return "Wrong one-time password";
  }

  return "Unknown error";
};

const getErrorMessageOnSignUp = (message: string) => {
  if (message.includes("has to be unique")) {
    return "The email entered is already used";
  }

  return "Unknown error";
};

const getErrorMessageOnRequestVerification = (message: string) => {
  if (message.includes("invalid or cannot be found")) {
    return "The email is invalid or cannot be found";
  }

  if (message.includes("as already been verified")) {
    return message;
  }

  return "Unknown error";
};

const getErrorMessageOnGenerateTfa = (message: string) => {
  if (message.includes("Invalid")) {
    return "The password submitted is incorrect";
  }

  return "Unknown error";
};

const checkIsOtpRequired = (message: string) => message === `"otp" is required`;

const signIn = async (data: TSignInForm) => {
  const notificationId = "signIn";

  loadingNotification("Checking your credentials...", notificationId);

  try {
    await directus.login(data.email, data.password, {
      otp: data.otp,
    });

    successNotification("Signed in successfully", notificationId);

    return await getUserData();
  } catch (error) {
    const errorCaught = error as AxiosError;

    const isOtpRequired = checkIsOtpRequired(errorCaught.message);
    if (isOtpRequired) {
      blankNotification("Enter your one-time password", notificationId);

      return {
        otpRequired: isOtpRequired,
      };
    }

    const errorMessage = getErrorMessageOnSignIn(errorCaught.message);

    errorNotification(errorMessage, notificationId);

    return false;
  }
};

const signOut = async (): Promise<void> => {
  const notificationId = "signOut";

  loadingNotification("Bye bye...", notificationId);

  await directus
    .logout()
    .catch()
    .finally(() => successNotification("Signed out successfully", notificationId));
};

const verifySignUp = async (token: string) => {
  const notificationId = "verifySignUp";

  const restClient = new Rest();

  loadingNotification("Verifying your account...", notificationId);

  try {
    await restClient.post("/register/verify", {
      token,
    });

    successNotification("Account verified successfully. You can sign in.", notificationId);

    return true;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorList = errorCaught.response?.data as {
      errors: [
        {
          message: string;
        }
      ];
    };

    errorNotification(errorList.errors[0].message, notificationId);

    return false;
  }
};

const requestVerification = async (email: string) => {
  const notificationId = "requestVerification";

  const restClient = new Rest();

  loadingNotification("Sending verification link...", notificationId);

  try {
    await restClient.post("/register/verification-request", {
      email,
    });

    successNotification("Verification sent successfully. Check your email and verify your account.", notificationId);

    return true;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorList = errorCaught.response?.data as {
      errors: [
        {
          message: string;
        }
      ];
    };

    const errorMessage = getErrorMessageOnRequestVerification(errorList.errors[0].message);

    errorNotification(errorMessage, notificationId);

    return false;
  }
};

const signUp = async (email: string, password: string, token: string) => {
  const notificationId = "signUp";

  const restClient = new Rest();

  loadingNotification("Creating your account...", notificationId);

  try {
    await restClient.post("/register", {
      email,
      password,
      token,
    });

    successNotification("Registered successfully. A verification email has been sent to your email.", notificationId);

    return true;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorList = errorCaught.response?.data as {
      errors: [
        {
          message: string;
        }
      ];
    };

    const errorMessage = getErrorMessageOnSignUp(errorList.errors[0].message);

    errorNotification(errorMessage, notificationId);

    return false;
  }
};

const forgotPassword = async (email: string) => {
  try {
    await directus
      .request(passwordRequest(email, `${window.location.origin}${routing.auth.resetPassword}`))
      .catch(() => null);
  } finally {
    successNotification("Your password reset email has been sent if this email exists");
  }
};

const updateEmail = async (email: string, password: string, newEmail: string, otp: string | undefined) => {
  const notificationId = "updateEmail";

  let response = false;

  try {
    try {
      await directus.login(email, password, {
        otp,
      });
    } catch (error) {
      const errorCaught = error as AxiosError;

      const errorMessage = getErrorMessageOnAuthentication(errorCaught.message);

      errorNotification(errorMessage, notificationId);

      return response;
    }

    await directus.request(updateMe({ email: newEmail }));

    response = true;

    successNotification("Email updated successfully", notificationId);
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorMessage = getErrorMessageOnUpdateEmail(errorCaught.message);

    errorNotification(errorMessage, notificationId);
  }

  return response;
};

const resetPassword = async (password: string, token: string) => {
  const notificationId = "resetPassword";

  loadingNotification("Resetting your password...", notificationId);

  try {
    await directus.request(passwordReset(token, password));

    successNotification("Password has been reset successfully", notificationId);

    return true;
  } catch {
    errorNotification("The token has expired. Please, request another one to reset your password", notificationId);

    return false;
  }
};

const closeAccount = async (
  email: string,
  password: string,
  userId: string,
  otp: string | undefined
): Promise<boolean> => {
  const notificationId = "closeAccount";

  loadingNotification("Closing your password...", notificationId);

  try {
    try {
      await directus.login(email, password, {
        otp,
      });
    } catch (error) {
      const errorCaught = error as AxiosError;

      const errorMessage = getErrorMessageOnAuthentication(errorCaught.message);

      errorNotification(errorMessage, notificationId);

      return false;
    }

    await directus.request(deleteUser(userId));

    await directus.logout().catch();

    directusAuthLocalStorage().remove();

    successNotification("Sad to see you go. Your account has been closed now.", notificationId);

    return true;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorMessage = getErrorMessageOnCloseAccount(errorCaught.message);

    errorNotification(errorMessage, notificationId);

    return false;
  }
};

const changePassword = async (
  email: string,
  currentPassword: string,
  newPassword: string,
  otp: string | undefined
): Promise<boolean> => {
  const notificationId = "changePassword";

  loadingNotification("Updating your password...", notificationId);

  try {
    try {
      await directus.login(email, currentPassword, {
        otp,
      });
    } catch (error) {
      const errorCaught = error as AxiosError;

      const errorMessage = getErrorMessageOnAuthentication(errorCaught.message);

      errorNotification(errorMessage, notificationId);

      return false;
    }
  } catch {
    errorNotification("Wrong current password", notificationId);

    return false;
  }

  try {
    await directus.request(updateMe({ password: newPassword }));

    successNotification("Password has been update", notificationId);

    return true;
  } catch {
    errorNotification("Error updating the password", notificationId);

    return false;
  }
};

const generateTfa = async (password: string): Promise<TTwoFactorAuthentication | null> => {
  const notificationId = "generateTfa";

  loadingNotification("Generating QR Code...", notificationId);

  try {
    const data = await directus.request(generateTwoFactorSecret(password));

    successNotification(
      "Scan the QR using an authenticator software for Time-based One-time passwords",
      notificationId
    );

    return data;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorMessage = getErrorMessageOnGenerateTfa(errorCaught.message);

    errorNotification(errorMessage, notificationId);

    return null;
  }
};

const disableTfa = async (otp: string) => {
  const notificationId = "disableTfa";

  let response = true;

  loadingNotification("Disabling your one-time password...", notificationId);

  try {
    await directus.request(disableTwoFactor(otp));

    successNotification("2FA disabled successfully", notificationId);

    return response;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorMessage = getErrorMessageOnDisableTfa(errorCaught.message);

    errorNotification(errorMessage, notificationId);

    response = false;

    return response;
  }
};

const enableTfa = async (secret: string, otp: string) => {
  const notificationId = "enableTfa";

  let response = true;

  loadingNotification("Checking your one-time password...", notificationId);

  try {
    await directus.request(enableTwoFactor(secret, otp));

    successNotification("2FA enabled successfully", notificationId);

    return response;
  } catch (error) {
    const errorCaught = error as AxiosError;

    const errorMessage = getErrorMessageOnEnableTfa(errorCaught.message);

    errorNotification(errorMessage, notificationId);

    response = false;

    return response;
  }
};

export {
  changePassword,
  closeAccount,
  disableTfa,
  enableTfa,
  forgotPassword,
  generateTfa,
  getUserData,
  getUserRoleNameByRoleId,
  requestVerification,
  resetPassword,
  signIn,
  signOut,
  signUp,
  updateEmail,
  verifySignUp,
};
