import React, { FC, memo } from "react";

import styled from "@emotion/styled";
import { Button as ButtonMUI, ButtonProps } from "@mui/material";
import Link from "next/link";

import content from "@/content";

export type ButtonVariant = "text" | "outlined" | "contained";

const testIdBase = content.components.button.testId;

interface IButton extends ButtonProps {
  hrefButton?: string;
  testId: string;
  variant: ButtonVariant;
}

const ButtonStyled = styled(ButtonMUI)`
  border-radius: 25px;
`;

const LinkStyled = styled.a`
  all: unset;
`;

const Button: FC<IButton> = ({ hrefButton, testId, variant, ...props }) => {
  if (hrefButton) {
    return (
      <Link href={hrefButton} passHref>
        <LinkStyled>
          <ButtonStyled data-testid={`${testIdBase}-${testId}`} {...props} variant={variant} />
        </LinkStyled>
      </Link>
    );
  }

  return <ButtonStyled data-testid={`${testIdBase}-${testId}`} {...props} variant={variant} />;
};

export type { IButton };

export default memo(Button);
