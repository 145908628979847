import React, { memo } from "react";

import styled from "@emotion/styled";
import MUIDataTable, { MUIDataTableColumn, MUIDataTableOptions } from "mui-datatables";

import content from "@/content";

const testIdBase = content.components.dataTable.testId;

interface IDataTable {
  columns: MUIDataTableColumn[];
  data: Array<object | number[] | string[]>;
  isVisible: boolean;
  options: MUIDataTableOptions | undefined;
  testId: string;
  title?: string;
}

export const Wrapper = styled.div``;

const DataTable = ({ columns, data, isVisible, options, testId, title }: IDataTable) => {
  return (
    <Wrapper data-testid={`${testIdBase}-${testId}`}>
      {isVisible ? <MUIDataTable columns={columns} data={data} options={options} title={title} /> : null}
    </Wrapper>
  );
};

export default memo(DataTable);
