import React, { memo, useEffect, useState } from "react";

import { Grid, Rating } from "@mui/material";
import { Form, Formik } from "formik";
import { useRouter } from "next/router";
import { object } from "yup";

import { Button, ComponentWrapper, Dialog, TextFieldForm, WarningUnsavedForm } from "@/components";
import content from "@/content";
import { submitFeedback } from "@/request/feedback";
import routing from "@/routing";
import { errorNotification, successNotification } from "@/utils/notifications";
import { feedbackCommentValidator, feedbackRatingValidator } from "@/utils/validator/feedback";

const testIdBase = content.containers.feedback.testId;

interface IFeedbackModal {
  bypassPreviousOpening?: boolean;
  isOpenFeedback: boolean;
  redirectOnSubmit?: boolean;
  redirectPathOnSubmit?: string;
  testId: string;
}

const isFeedbackShown = () => !!!localStorage.getItem("isFeedbackShown");

const FeedbackModal = ({
  bypassPreviousOpening,
  isOpenFeedback,
  redirectOnSubmit,
  redirectPathOnSubmit,
  testId,
}: IFeedbackModal) => {
  const router = useRouter();

  const validationSchema = object({
    ...feedbackRatingValidator,
    ...feedbackCommentValidator,
  });

  const [isModalOpen, setIsModalOpen] = useState(isOpenFeedback);

  const [isSubmitting, setSubmitting] = useState(false);

  const [valuesForm, setValuesForm] = useState<TFeedbackModal>({
    comment: content.validators.commentAction.defaultValue,
    rating: content.validators.ratingAction.defaultValue,
  });

  const onClosingModal = () => {
    setIsModalOpen(false);

    if (redirectOnSubmit) {
      router.push(redirectPathOnSubmit || routing.home);
    }
  };

  useEffect(() => {
    if (isOpenFeedback) {
      const isFeedbackShown = localStorage.getItem("isFeedbackShown");

      /**
       * Bypassing any previous state of the feedback modal to force to be opened
       */
      if (bypassPreviousOpening) {
        setIsModalOpen(true);
      }

      /**
       * Only opening the modal when the bypassed is false or undefined and the
       * feedback has been been opened by the user on the session
       */
      if (!isFeedbackShown && !bypassPreviousOpening) {
        setIsModalOpen(true);

        localStorage.setItem("isFeedbackShown", "true");
      }
    }
  }, [bypassPreviousOpening, isOpenFeedback]);

  const onSubmitForm = async (data: TFeedbackModal) => {
    setSubmitting(true);

    setValuesForm(data);

    const notificationId = "feedbackModal";

    try {
      await submitFeedback(data.rating, data.comment);

      successNotification(content.containers.feedback.onSubmitted, notificationId);

      setSubmitting(false);

      onClosingModal();

      return true;
    } catch (error) {
      const errorCaught = error as Error;

      errorNotification(errorCaught.message, notificationId);

      setSubmitting(false);
    }

    return false;
  };

  return (
    <div data-testid={`${testIdBase}-${testId}`}>
      <Dialog
        content={
          <Formik
            initialValues={valuesForm}
            onSubmit={async (data, { resetForm }) => {
              const isSubmitted = await onSubmitForm(data);

              if (isSubmitted) {
                resetForm({
                  values: data,
                });
              }
            }}
            validationSchema={validationSchema}
          >
            {({ dirty, setFieldValue, values }) => (
              <Form>
                <WarningUnsavedForm areChangesUnsaved={dirty} />
                <Grid alignItems="stretch" container direction="column" justifyContent="center" spacing={3}>
                  <Grid item xs={12}>
                    <Rating
                      name={content.containers.feedback.rating.name}
                      onChange={(_, value) => {
                        /**
                         * The component should return between 1 and 5, and when the value is null
                         * the rating should be the minimum as 1
                         */
                        if (!value) {
                          value = 1;
                        }

                        setFieldValue(content.containers.feedback.rating.name, value);
                      }}
                      value={values.rating}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextFieldForm
                      name={content.containers.feedback.form.comment.name}
                      props={{
                        fullWidth: true,
                        helperText: content.containers.feedback.form.comment.helperText,
                        label: content.containers.feedback.form.comment.label,
                        multiline: true,
                        placeholder: content.containers.feedback.form.comment.placeholder,
                        required: false,
                        type: "text",
                        variant: "outlined",
                      }}
                      testId={testIdBase}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <ComponentWrapper>
                      <Button disabled={isSubmitting} fullWidth testId={testIdBase} type="submit" variant="contained">
                        {content.containers.feedback.form.submit.text}
                      </Button>
                    </ComponentWrapper>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        }
        fullWidth
        isDisableLeftButton
        maxWidth="xs"
        onClickRightButton={onClosingModal}
        open={isModalOpen}
        rightButton={content.containers.feedback.dialog.rightButton.label}
        testId={testIdBase}
        title={content.containers.feedback.dialog.title}
      />
    </div>
  );
};

export { isFeedbackShown };

export default memo(FeedbackModal);
