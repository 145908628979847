import React, { memo } from "react";

import styled from "@emotion/styled";
import ClipLoader from "react-spinners/ClipLoader";

import content from "@/content";
import { theme } from "@/styles";
import { ComponentChildren } from "@/types";

const testIdBase = content.components.loaderSpinner.testId;

export interface IClipLoader extends ComponentChildren {
  color?: string;
  isLoading: boolean;
  size?: number;
}

const WrapperLoader = styled.div``;

const LoaderSpinner = (props: IClipLoader) => {
  const { children, color, isLoading, size } = props;

  if (isLoading) {
    return (
      <WrapperLoader data-testid={testIdBase}>
        <ClipLoader color={color} loading={isLoading} size={size} />
      </WrapperLoader>
    );
  }

  return <>{children}</>;
};

LoaderSpinner.defaultProps = {
  children: null,
  color: theme.palette.primary.dark,
  size: 150,
};

export default memo(LoaderSpinner);
