import React, { memo } from "react";

import { TextField as TextFieldMUI, TextFieldProps } from "@mui/material";

import content from "@/content";

const testIdBase = content.components.textField.testId;

export interface ITextField {
  props: TextFieldProps;
  testId: string;
}

const TextField = ({ props, testId }: ITextField) => {
  return <TextFieldMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(TextField);
