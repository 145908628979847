import styled from "@emotion/styled";

interface WrapperProps {
  margin?: string;
}

const Wrapper = styled.div<WrapperProps>`
  text-align: center;
  margin: ${(props) => props.margin};
`;

export default Wrapper;
