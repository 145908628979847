import { toast } from "react-hot-toast";

type TPromiseNotifications = {
  error: string;
  loading: string;
  success: string;
};

const successNotification = (message: string, id?: string) => {
  return toast.success(message, {
    id: id || message,
  });
};

const blankNotification = (message: string, id?: string): string => {
  return toast(message, {
    id: id || message,
  });
};

const errorNotification = (message: string, id?: string): string => {
  return toast.error(message, {
    id: id || message,
  });
};

const loadingNotification = (message: string, id?: string): string => {
  return toast.loading(message, {
    id: id || message,
  });
};

const promiseNotification = (promise: Promise<unknown>, config: TPromiseNotifications) => {
  return toast.promise(promise, config);
};

const dismissNotification = (id?: string): void => {
  toast.dismiss(id);
};

export {
  blankNotification,
  dismissNotification,
  errorNotification,
  loadingNotification,
  promiseNotification,
  successNotification,
};
