import React, { memo } from "react";

import { default as ContentCopyMUI } from "@mui/icons-material/ContentCopy";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.copy.testId;

const CopyIcon = ({ testId, ...props }: Icon) => {
  return <ContentCopyMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(CopyIcon);
