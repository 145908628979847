import React, { memo } from "react";

import { default as SettingsIconMUI } from "@mui/icons-material/Settings";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.settings.testId;

const SettingsIcon = ({ testId, ...props }: Icon) => {
  return <SettingsIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(SettingsIcon);
