import React, { memo, useState } from "react";

import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { NextRouter } from "next/router";
import { object } from "yup";

import { Button, ComponentWrapper, TextFieldForm, WarningUnsavedForm } from "@/components";
import content from "@/content";
import { getUserDataAsyncThunk } from "@/context/AuthenticationContext";
import { useAppDispatch } from "@/context/store";
import { disableTfa } from "@/request/user";
import { userOtpValidator } from "@/utils/validator/user";

const testIdBase = content.containers.disableTfaForm.testId;

interface IDisableTFAForm {
  router: NextRouter;
  setRedirecting: (value: boolean) => void;
}

const DisableTFAForm = ({ router, setRedirecting }: IDisableTFAForm) => {
  const dispatch = useAppDispatch();

  const [valuesForm, setValuesForm] = useState<{ otp: string }>({
    otp: "",
  });

  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const validationSchemaForm = object({
    ...userOtpValidator,
  });

  const onSubmitForm = async ({ otp }: TDisableTFAForm) => {
    setValuesForm({ otp });

    setSubmitting(true);

    const isTfaDisabled = await disableTfa(otp);

    if (isTfaDisabled) {
      setRedirecting(true);

      dispatch(getUserDataAsyncThunk());

      router.push(content.containers.disableTfaForm.onDisableTfa.redirect.route);
    }

    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={valuesForm}
      onSubmit={async (data, { resetForm }) => {
        resetForm({
          values: data,
        });

        await onSubmitForm(data);
      }}
      validationSchema={validationSchemaForm}
    >
      {({ dirty }) => (
        <Form>
          <WarningUnsavedForm areChangesUnsaved={dirty} />
          <Grid alignItems="stretch" container direction="column" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <TextFieldForm
                name={content.containers.enableTfaForm.form.otp.name}
                props={{
                  fullWidth: true,
                  helperText: content.containers.enableTfaForm.form.otp.helperText,
                  label: content.containers.enableTfaForm.form.otp.label,
                  type: "text",
                  variant: "outlined",
                }}
                testId={testIdBase}
              />
            </Grid>

            <Grid item xs={6}>
              <ComponentWrapper>
                <Button disabled={isSubmitting} fullWidth testId={testIdBase} type="submit" variant="contained">
                  {content.containers.enableTfaForm.form.button.submit.text}
                </Button>
              </ComponentWrapper>

              <Button
                disabled={isSubmitting}
                fullWidth
                onClick={() => router.push(content.containers.enableTfaForm.form.button.redirect.route)}
                size="small"
                testId={testIdBase}
                variant="outlined"
              >
                {content.containers.enableTfaForm.form.button.redirect.text}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default memo(DisableTFAForm);
