import React, { memo } from "react";

import Divider from "@mui/material/Divider";
import MenuList from "@mui/material/MenuList";
import { NextRouter } from "next/router";

import NavigationMenuItem, { IMenuItem } from "@/containers/NavigationMenuItem/NavigationMenuItem";
import content from "@/content";
import {
  AppsSharpIcon,
  BalanceIcon,
  ExitToAppIcon,
  FolderIcon,
  GavelIcon,
  HelpOutlineIcon,
  HomeIcon,
  InfoIcon,
  LockIcon,
  LoginIcon,
  NoteAddIcon,
  PeopleIcon,
  PersonAddAltIcon,
  PersonIcon,
  QuestionAnswerIcon,
} from "@/icons";
import routing from "@/routing";
import { Role } from "@/utils/permissions";

const testIdBase = content.containers.navigationBar.testId;

const menuItemsTop: IMenuItem[] = [
  {
    IconComponent: HomeIcon,
    label: "Home",
    route: routing.home,
    scope: Role.ALL,
  },
  {
    IconComponent: InfoIcon,
    label: "About",
    route: routing.content.aboutUs,
    scope: Role.ALL,
  },
  {
    IconComponent: AppsSharpIcon,
    items: [
      {
        IconComponent: QuestionAnswerIcon,
        label: "Submit Legal enquiry",
        route: routing.enquiry.create,
        scope: Role.ALL,
      },
      {
        IconComponent: NoteAddIcon,
        label: "Create Doc for Family Court",
        route: routing.form.create.unauthenticated,
        scope: Role.UNAUTHENTICATED,
      },
      {
        IconComponent: NoteAddIcon,
        label: "Create Doc for Family Court",
        route: routing.form.create.authenticated,
        scope: Role.AUTHENTICATED,
      },
      {
        IconComponent: GavelIcon,
        label: "Legal Advice Drop-in Clinic",
        route: routing.content.legalAdviceDropInClinic,
        scope: Role.ALL,
      },
      {
        IconComponent: BalanceIcon,
        label: "Windrush Justice Clinic",
        route: routing.content.windrushJusticeClinic,
        scope: Role.ALL,
      },
    ],
    label: "Services",
    route: routing.services,
    scope: Role.ALL,
  },
  {
    IconComponent: HelpOutlineIcon,
    label: "Help",
    route: routing.content.help,
    scope: Role.ALL,
  },
];

const menuItemsUserAuthenticated: IMenuItem[] = [
  {
    IconComponent: PersonIcon,
    label: "My Account",
    route: routing.user.profile,
    scope: Role.AUTHENTICATED,
  },
  {
    IconComponent: FolderIcon,
    label: "My Family Court Forms",
    route: routing.form.list,
    scope: Role.AUTHENTICATED,
  },
  {
    IconComponent: PeopleIcon,
    label: "Forms Shared with me",
    route: routing.form.sharedWithMe,
    scope: Role.AUTHENTICATED,
  },
  {
    IconComponent: ExitToAppIcon,
    label: "Sign Out",
    route: routing.auth.signOut,
    scope: Role.AUTHENTICATED,
  },
];

const menuItemsUserUnauthenticated: IMenuItem[] = [
  {
    IconComponent: LoginIcon,
    label: "Sign In",
    route: routing.auth.signIn,
    scope: Role.UNAUTHENTICATED,
  },
  {
    IconComponent: PersonAddAltIcon,
    label: "Register",
    route: routing.auth.signUp,
    scope: Role.UNAUTHENTICATED,
  },
  {
    IconComponent: LockIcon,
    label: "Forgot Password",
    route: routing.auth.forgotPassword,
    scope: Role.UNAUTHENTICATED,
  },
];

const menuItemsUser = [...menuItemsUserAuthenticated, ...menuItemsUserUnauthenticated];

interface INavigationMenuList {
  onDrawerChange: (status: boolean) => void;
  router: NextRouter;
}

const NavigationMenuList = ({ onDrawerChange, router }: INavigationMenuList) => {
  return (
    <>
      <MenuList data-testid={testIdBase}>
        {menuItemsTop.map(({ IconComponent, items, label, route, scope }) => {
          return (
            <NavigationMenuItem
              IconComponent={IconComponent}
              items={items}
              key={`${label}-${scope}`}
              label={label}
              onDrawerChange={onDrawerChange}
              route={route}
              router={router}
              scope={scope}
            />
          );
        })}
      </MenuList>

      <Divider />

      <MenuList data-testid={testIdBase}>
        {menuItemsUser.map(({ IconComponent, items, label, route, scope }) => {
          return (
            <NavigationMenuItem
              IconComponent={IconComponent}
              items={items}
              key={`${label}-${scope}`}
              label={label}
              onDrawerChange={onDrawerChange}
              route={route}
              router={router}
              scope={scope}
            />
          );
        })}
      </MenuList>
    </>
  );
};

export default memo(NavigationMenuList);
