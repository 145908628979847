import React, { memo } from "react";

import { default as SaveIconMUI } from "@mui/icons-material/Save";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.save.testId;

const SaveIcon = ({ testId, ...props }: Icon) => {
  return <SaveIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(SaveIcon);
