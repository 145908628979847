import React, { memo } from "react";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

import content from "@/content";

type TPasswordVisibilityToggle = {
  changeVisibility: (state: boolean) => void;
  isPasswordVisible: boolean;
  testId: string;
};

const testIdBase = content.components.passwordVisibilityToggle.testId;

const PasswordVisibilityToggle = ({ changeVisibility, isPasswordVisible, testId }: TPasswordVisibilityToggle) => {
  return (
    <Tooltip
      data-testid={`${testIdBase}-${testId}`}
      title={
        isPasswordVisible
          ? content.components.passwordVisibilityToggle.passwordVisible
          : content.components.passwordVisibilityToggle.passwordNotVisible
      }
    >
      <IconButton onClick={() => changeVisibility(!isPasswordVisible)}>
        {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </Tooltip>
  );
};

export default memo(PasswordVisibilityToggle);
