import React, { memo } from "react";

import { default as AppsSharpIconMUI } from "@mui/icons-material/AppsSharp";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.edit.testId;

const AppsSharpIcon = ({ testId, ...props }: Icon) => {
  return <AppsSharpIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(AppsSharpIcon);
