import React, { memo, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { Dialog } from "@/components";
import Typography from "@/components/Typography/Typography";
import content from "@/content";
import routing from "@/routing";

const testIdBase = content.containers.firstTimeVisit.testId;

const FirstTimeVisitModal = () => {
  const router = useRouter();

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const isFirstVisit = localStorage.getItem("isFirstVisit");

    if (!isFirstVisit) {
      setShowModal(true);

      localStorage.setItem("isFirstVisit", "true");
    }
  }, []);

  const onModalAction = () => setShowModal(false);

  const onRightButtonAction = () => {
    onModalAction();
    router.push(routing.content.help);
  };

  return (
    <Dialog
      content={<Typography testId={testIdBase}>{content.containers.firstTimeVisit.dialog.content}</Typography>}
      leftButton={content.containers.firstTimeVisit.dialog.leftButton.label}
      maxWidth="xs"
      onClickLeftButton={onModalAction}
      onClickRightButton={onRightButtonAction}
      open={showModal}
      rightButton={content.containers.firstTimeVisit.dialog.rightButton.label}
      rightButtonVariant="contained"
      testId={testIdBase}
      title={content.containers.firstTimeVisit.dialog.title}
    />
  );
};

export default memo(FirstTimeVisitModal);
