import React, { memo } from "react";

import { default as GridViewIconMUI } from "@mui/icons-material/GridView";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.emailSent.testId;

const GridViewIcon = ({ testId, ...props }: Icon) => {
  return <GridViewIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(GridViewIcon);
