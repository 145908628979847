import React, { memo } from "react";

import { default as MenuIconMUI } from "@mui/icons-material/Menu";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.menu.testId;

const MenuIcon = ({ testId, ...props }: Icon) => {
  return <MenuIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(MenuIcon);
