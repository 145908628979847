import React, { createContext, memo, useEffect, useRef, useState } from "react";

import Head from "next/head";

import Footer from "@/containers/Footer/Footer";
import { Body, Main } from "@/containers/Layout/Layout.styles";
import NavigationBar from "@/containers/NavigationBar/NavigationBar";
import content from "@/content";
import { getUserDataAsyncThunk } from "@/context/AuthenticationContext";
import { useAppDispatch } from "@/context/store";
import { ComponentChildren } from "@/types";

const testIdBase = content.containers.layout.testId;

export const FooterHeightContext = createContext<number | null>(null);

const Layout = ({ children }: ComponentChildren) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserDataAsyncThunk());
  }, [dispatch]);

  const footerRef = useRef<HTMLDivElement | null>(null);

  const [footerHeight, setFooterHeight] = useState<number | null>(null);

  useEffect(() => {
    if (footerRef.current) {
      const height = footerRef.current.offsetHeight;

      setFooterHeight(height);
    }
  }, [footerRef]);

  return (
    <>
      <Head>
        <title>{content.containers.layout.headTitle}</title>
        <meta content={content.containers.layout.headDescription} name="description" />
        <link href="/favicon.ico" rel="icon" />
      </Head>
      <Body data-testid={`${testIdBase}-body`}>
        <FooterHeightContext.Provider value={footerHeight}>
          <NavigationBar />
          <Main data-testid={`${testIdBase}-main`}>{children}</Main>
          <Footer ref={footerRef} />
        </FooterHeightContext.Provider>
      </Body>
    </>
  );
};

export default memo(Layout);
