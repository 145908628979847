import React, { memo } from "react";

import styled from "@emotion/styled";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { useRouter } from "next/router";

import Button from "@/components/Button/Button";
import content from "@/content";
import { CenteredContent } from "@/styles";

const testIdBase = content.components.serviceCard.testId;

const StyledCard = styled(Card)`
  width: 350px;
  height: 400px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);

  @media (max-width: 800px) {
    width: 300px;
  }
`;

const ServiceButton = styled(Button)`
  margin-top: auto;
  margin-bottom: 1rem;
`;

interface IServiceCard {
  buttonRedirectLabel: string;
  buttonRedirectPath: string;
  description: string;
  imageAlt: string;
  imageSrc: string;
  testId: string;
  title: string;
}

const ServiceTitle = styled(Typography)`
  @media (max-width: 800px) {
    font-size: 1.2rem;
  }
`;

const ServiceCard = ({
  buttonRedirectLabel,
  buttonRedirectPath,
  description,
  imageAlt,
  imageSrc,
  testId,
  title,
}: IServiceCard) => {
  const router = useRouter();

  return (
    <StyledCard data-testid={`${testIdBase}-${testId}`}>
      <CardMedia alt={imageAlt} component="img" height="194" image={imageSrc} />
      <CardContent>
        <ServiceTitle gutterBottom variant="h6">
          {title}
        </ServiceTitle>
        <Typography color="text.secondary" variant="body2">
          {description}
        </Typography>
      </CardContent>
      <CenteredContent>
        <ServiceButton onClick={() => router.push(buttonRedirectPath)} size="small" testId={testId} variant="contained">
          {buttonRedirectLabel}
        </ServiceButton>
      </CenteredContent>
    </StyledCard>
  );
};

export default memo(ServiceCard);
