import { boolean, ref, string } from "yup";

import content from "@/content";

const userEmailValidator = {
  email: string()
    .default(content.validators.userEmail.defaultValue)
    .email(content.validators.userEmail.invalid)
    .required(content.validators.userEmail.required)
    .trim(),
};

const userEmailNewValidator = {
  emailNew: string()
    .default(content.validators.userEmailUpdate.defaultValue)
    .email(content.validators.userEmailUpdate.invalid)
    .notOneOf([ref("email"), null], content.validators.userEmailUpdate.notOneOf)
    .required(content.validators.userEmailUpdate.required)
    .trim(),
};

const userPasswordValidator = {
  password: string()
    .required(content.validators.userPassword.required)
    .matches(content.validators.userPassword.regex, content.validators.userPassword.regexInvalid),
};

const userPasswordNewValidator = {
  password: string()
    .required(content.validators.userPasswordNew.required)
    .notOneOf([ref("passwordCurrent")], content.validators.userPasswordNew.notOneOf)
    .matches(content.validators.userPasswordNew.regex, content.validators.userPasswordNew.regexInvalid),
};

const userPasswordConfirmationValidator = {
  passwordConfirmation: string()
    .oneOf([ref("password")], content.validators.userPasswordConfirmation.ref)
    .required(content.validators.userPasswordConfirmation.required),
};

const userPasswordCurrentValidator = {
  passwordCurrent: string()
    .required(content.validators.userPasswordCurrent.required)
    .matches(content.validators.userPasswordCurrent.regex, content.validators.userPasswordCurrent.regexInvalid),
};

const userSignUpCheckValidator = {
  checkTaC: boolean().oneOf([true], content.validators.userSignUpCheckTaC.oneOf),
};

const userOtpValidator = {
  otp: string()
    .matches(content.validators.userOtp.regex, content.validators.userOtp.regexInvalid)
    .test("len", content.validators.userOtp.test, (otp) => (!otp ? false : otp.toString().length === 6))
    .required(content.validators.userOtp.required),
};

export {
  userEmailNewValidator,
  userEmailValidator,
  userOtpValidator,
  userPasswordConfirmationValidator,
  userPasswordCurrentValidator,
  userPasswordNewValidator,
  userPasswordValidator,
  userSignUpCheckValidator,
};
