import axios, { AxiosInstance } from "axios";

class Rest {
  service: AxiosInstance;

  constructor(token?: string) {
    let headers = {};

    if (token) {
      headers = {
        Authorization: `Bearer ${token}`,
      };
    }

    const service = axios.create({
      baseURL: process.env.NEXT_PUBLIC_BACKEND_ENDPOINT,
      headers,
    });

    this.service = service;
  }

  get = async (path: string) =>
    this.service.request({
      method: "GET",
      responseType: "json",
      url: path,
    });

  post = async (path: string, payload: object) =>
    this.service.request({
      data: payload,
      method: "POST",
      responseType: "json",
      url: path,
    });
}

export default Rest;
