import { configureStore } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

import AuthenticationContext from "@/context/AuthenticationContext";

export const store = configureStore({
  reducer: {
    authentication: AuthenticationContext,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<typeof store.dispatch>();
