import React, { memo } from "react";

import { default as DashboardMUI } from "@mui/icons-material/Dashboard";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.dashboard.testId;

const DashboardIcon = ({ testId, ...props }: Icon) => {
  return <DashboardMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(DashboardIcon);
