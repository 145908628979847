import { memo, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { RootState } from "@/context/store";
import { ElementChildren } from "@/types";
import { Role, isScopeAuthorized } from "@/utils/permissions";

interface IAuthenticationScope extends ElementChildren {
  scope: Role | Role[];
}

const AuthenticationScope = ({ children, scope }: IAuthenticationScope) => {
  const { loading, user } = useSelector((state: RootState) => state.authentication);

  const [authorized, setAuthorized] = useState<boolean>(false);

  useEffect(() => {
    setAuthorized(isScopeAuthorized(scope, user.roleName));
  }, [loading, scope, user.roleName]);

  if (authorized) {
    return children;
  }

  return null;
};

export default memo(AuthenticationScope);
