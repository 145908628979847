import { number, string } from "yup";

import content from "@/content";

const feedbackRatingValidator = {
  rating: number().required(content.validators.ratingAction.required).max(5, content.validators.ratingAction.max),
};

const feedbackCommentValidator = {
  comment: string().max(255, content.validators.commentAction.max),
};

export { feedbackCommentValidator, feedbackRatingValidator };
