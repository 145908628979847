import React, { memo, useEffect, useState } from "react";

import { useRouter } from "next/router";
import { useSelector } from "react-redux";

import LoadingContainer from "@/containers/LoadingContainer/LoadingContainer";
import { RootState } from "@/context/store";
import routing from "@/routing";
import { CenteredContent } from "@/styles";
import { ElementChildren } from "@/types";
import { Role, isScopeAuthorized } from "@/utils/permissions";

interface IAuthenticatedRoute extends ElementChildren {
  scope?: Role[] | Role;
}

const AuthenticatedRoute = ({ children, scope }: IAuthenticatedRoute) => {
  const router = useRouter();

  const { loading, user } = useSelector((state: RootState) => state.authentication);

  const [isRouteVerified, setRouteVerified] = useState<boolean>(false);

  const [isRedirecting, setRedirecting] = useState<boolean>(false);

  const isUserSignedIn = user.isAuthenticated;

  useEffect(() => {
    if (!loading) {
      if (
        !isUserSignedIn &&
        window.location.pathname !== routing.auth.signIn &&
        !isScopeAuthorized(scope, user.roleName)
      ) {
        router.push({
          pathname: routing.auth.signIn,
          query: {
            redirectUrl: window.location.pathname,
          },
        });

        setRedirecting(true);
      } else if (isUserSignedIn && scope && user && !isScopeAuthorized(scope, user.roleName)) {
        router.push(routing.unauthorized);

        setRedirecting(true);
      }

      setRouteVerified(true);
    }
  }, [isUserSignedIn, loading, scope, router, user]);

  if (loading || !isRouteVerified || isRedirecting) {
    return (
      <CenteredContent>
        <LoadingContainer loading={loading} />
      </CenteredContent>
    );
  }

  return children;
};

AuthenticatedRoute.defaultProps = { scope: [] };

export default memo(AuthenticatedRoute);
