import styled from "@emotion/styled";

export const Body = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const container = styled.div`
  .container {
    width: 100%;
  }

  @media (min-width: 640px) {
    .container {
      max-width: 640px;
    }
  }

  @media (min-width: 768px) {
    .container {
      max-width: 768px;
    }
  }

  @media (min-width: 1024px) {
    .container {
      max-width: 1024px;
    }
  }

  @media (min-width: 1280px) {
    .container {
      max-width: 1280px;
    }
  }
`;

export const Main = styled(container)`
  flex: 1;
  display: flex;
  padding: 0;
`;
