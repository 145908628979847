import React, { memo } from "react";

import { default as CheckIconMUI } from "@mui/icons-material/Check";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.check.testId;

const CheckIcon = ({ testId, ...props }: Icon) => {
  return <CheckIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(CheckIcon);
