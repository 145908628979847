import React, { memo } from "react";

import styled from "@emotion/styled";
import { MenuList } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import { NextRouter } from "next/router";

import AuthenticationScope from "@/containers/AuthenticationScope/AuthenticationScope";
import content from "@/content";
import { Icon } from "@/icons/type";
import { Role } from "@/utils/permissions";

const testIdBase = content.containers.navigationMenuItem.testId;

type IMenuItem = {
  IconComponent: React.MemoExoticComponent<({ testId, ...props }: Icon) => JSX.Element>;
  items?: IMenuItem[];
  label: string;
  route: string;
  scope: Role | Role[];
};

interface INavigationMenuItem extends IMenuItem {
  onDrawerChange: (status: boolean) => void;
  router: NextRouter;
}

const SubMenuItem = styled(MenuList)`
  left: 10px;
`;

const NavigationMenuItem = ({
  IconComponent,
  items,
  label,
  onDrawerChange,
  route,
  router,
  scope,
}: INavigationMenuItem) => {
  const onMenuItemClick = () => {
    onDrawerChange(false);
    router.push(route);
  };

  if (items) {
    return (
      <React.Fragment>
        <MenuItem onClick={onMenuItemClick}>
          <ListItemIcon>
            <IconComponent fontSize="small" testId={testIdBase} />
          </ListItemIcon>

          <ListItemText
            primaryTypographyProps={{
              fontSize: "0.9rem",
            }}
          >
            {label}
          </ListItemText>
        </MenuItem>

        <SubMenuItem data-testid={testIdBase}>
          {items.map(({ IconComponent, items, label, route, scope }) => {
            return (
              <NavigationMenuItem
                IconComponent={IconComponent}
                items={items}
                key={`${label}-${scope}`}
                label={label}
                onDrawerChange={onDrawerChange}
                route={route}
                router={router}
                scope={scope}
              />
            );
          })}
        </SubMenuItem>
      </React.Fragment>
    );
  }

  return (
    <AuthenticationScope scope={scope}>
      <MenuItem data-testid={testIdBase} onClick={onMenuItemClick}>
        <ListItemIcon>
          <IconComponent fontSize="small" testId={testIdBase} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            fontSize: "0.9rem",
          }}
        >
          {label}
        </ListItemText>
      </MenuItem>
    </AuthenticationScope>
  );
};

export type { IMenuItem };

export default memo(NavigationMenuItem);
