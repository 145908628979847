import React, { FC, memo } from "react";

import { Typography as TypographyMUI, TypographyProps } from "@mui/material";

import content from "@/content";

const testIdBase = content.components.typography.testId;

interface ITypography extends TypographyProps {
  testId: string;
}

const Typography: FC<ITypography> = ({ testId, ...props }) => {
  return <TypographyMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(Typography);
