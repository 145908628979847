import React, { memo } from "react";

import { default as FolderIconMUI } from "@mui/icons-material/Folder";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.folderIcon.testId;

const FolderIcon = ({ testId, ...props }: Icon) => {
  return <FolderIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(FolderIcon);
