import React, { memo } from "react";

import { default as GroupsMUI } from "@mui/icons-material/Groups";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.team.testId;

const TeamIcon = ({ testId, ...props }: Icon) => {
  return <GroupsMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(TeamIcon);
