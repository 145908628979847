import React, { memo, useState } from "react";

import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { NextRouter } from "next/router";
import { QRCodeCanvas } from "qrcode.react";
import { object } from "yup";

import { Button, ComponentWrapper, TextFieldForm, WarningUnsavedForm } from "@/components";
import content from "@/content";
import { getUserDataAsyncThunk } from "@/context/AuthenticationContext";
import { useAppDispatch } from "@/context/store";
import { CopyIcon } from "@/icons";
import { enableTfa } from "@/request/user";
import { successNotification } from "@/utils/notifications";
import { userOtpValidator } from "@/utils/validator/user";

interface IEnableTFAForm {
  isSubmitting: boolean;
  qrCodeOutputUrl: string;
  qrCodeSecret: string;
  router: NextRouter;
  setRedirecting: (value: boolean) => void;
  setSubmitting: (value: boolean) => void;
}

const testIdBase = content.containers.enableTfaForm.testId;

const EnableTFAForm = ({
  isSubmitting,
  qrCodeOutputUrl,
  qrCodeSecret,
  router,
  setRedirecting,
  setSubmitting,
}: IEnableTFAForm) => {
  const dispatch = useAppDispatch();

  const [valuesForm, setValuesForm] = useState<{ otp: string }>({
    otp: "",
  });

  const validationSchemaForm = object({
    ...userOtpValidator,
  });

  const onEnableTfa = async ({ otp }: { otp: string }) => {
    setSubmitting(true);

    setValuesForm({ otp });

    const isTfaEnabled = await enableTfa(qrCodeSecret, otp);

    if (isTfaEnabled) {
      setRedirecting(true);

      dispatch(getUserDataAsyncThunk());

      router.push(content.containers.enableTfaForm.onEnableTfa.redirect.route);
    }

    setSubmitting(false);
  };

  const copyQrCodeOutputUrl = () => {
    navigator.clipboard.writeText(qrCodeOutputUrl);
    successNotification("The QR Code has been copied to your clipboard");
  };

  return (
    <Formik
      initialValues={valuesForm}
      onSubmit={async (data, { resetForm }) => {
        await onEnableTfa(data);
        resetForm({
          values: data,
        });
      }}
      validationSchema={validationSchemaForm}
    >
      {({ dirty }) => (
        <Form>
          <WarningUnsavedForm areChangesUnsaved={dirty} />
          <Grid alignItems="stretch" container direction="column" justifyContent="center" spacing={3}>
            <Grid item xs={6}>
              <ComponentWrapper>
                <Grid alignItems="center" container direction="column" justifyContent="center" spacing={3}>
                  <Grid item>
                    <QRCodeCanvas size={200} value={qrCodeOutputUrl} />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={copyQrCodeOutputUrl}
                      size="medium"
                      startIcon={<CopyIcon color="inherit" fontSize="small" testId={testIdBase} />}
                      testId={testIdBase}
                      variant={"outlined"}
                    >
                      Copy
                    </Button>
                  </Grid>
                </Grid>
              </ComponentWrapper>

              <TextFieldForm
                name={content.containers.enableTfaForm.form.otp.name}
                props={{
                  fullWidth: true,
                  helperText: content.containers.enableTfaForm.form.otp.helperText,
                  label: content.containers.enableTfaForm.form.otp.label,
                  type: "text",
                  variant: "outlined",
                }}
                testId={testIdBase}
              />
            </Grid>

            <Grid item xs={6}>
              <ComponentWrapper>
                <Button disabled={isSubmitting} fullWidth testId={testIdBase} type="submit" variant="contained">
                  {content.containers.enableTfaForm.form.button.submit.text}
                </Button>
              </ComponentWrapper>

              <Button
                disabled={isSubmitting}
                fullWidth
                onClick={() => router.push(content.containers.enableTfaForm.form.button.redirect.route)}
                size="small"
                testId={testIdBase}
                variant="outlined"
              >
                {content.containers.enableTfaForm.form.button.redirect.text}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default memo(EnableTFAForm);
