import React, { memo } from "react";

import { default as EmailMUI } from "@mui/icons-material/Email";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.email.testId;

const EmailIcon = ({ testId, ...props }: Icon) => {
  return <EmailMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(EmailIcon);
