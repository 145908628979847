import React, { memo } from "react";

import { default as LockIconMUI } from "@mui/icons-material/Lock";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.lock.testId;

const LockIcon = ({ testId, ...props }: Icon) => {
  return <LockIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(LockIcon);
