import React, { memo } from "react";

import { default as FileUploadIconMUI } from "@mui/icons-material/FileUpload";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.upload.testId;

const UploadIcon = ({ testId, ...props }: Icon) => {
  return <FileUploadIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(UploadIcon);
