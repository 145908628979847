import React, { FC, memo } from "react";

import { Link as LinkMUI, LinkProps } from "@mui/material";

import content from "@/content";

interface ILink extends LinkProps {
  testId: string;
}

const testIdBase = content.components.link.testId;

const Link: FC<ILink> = ({ testId, ...props }) => {
  return <LinkMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(Link);
