import React, { memo } from "react";

import { default as QuestionAnswerMUI } from "@mui/icons-material/QuestionAnswer";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.questionAnswer.testId;

const QuestionAnswerIcon = ({ testId, ...props }: Icon) => {
  return <QuestionAnswerMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(QuestionAnswerIcon);
