import React, { memo } from "react";

import content from "@/content";
import { SVG } from "@/icons/type";

const testIdBase = content.icons.happyFaceEmoji.testId;

const HappyFaceEmoji = ({ testId, ...props }: SVG) => (
  <svg
    data-testid={`${testIdBase}-${testId}`}
    id="emoji"
    viewBox="0 0 72 72"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="color">
      <circle cx={36} cy={36} fill="#fcea2b" r={23} />
      <path
        d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"
        fill="#fff"
      />
      <path
        d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"
        fill="#ea5a47"
      />
    </g>
    <g id="hair" />
    <g id="skin" />
    <g id="skin-shadow" />
    <g id="line">
      <circle
        cx={36}
        cy={36}
        fill="none"
        r={23}
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M50.595,41.64a11.5554,11.5554,0,0,1-.87,4.49c-12.49,3.03-25.43.34-27.49-.13a11.4347,11.4347,0,0,1-.83-4.36h.11s14.8,3.59,28.89.07Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M49.7251,46.13c-1.79,4.27-6.35,7.23-13.69,7.23-7.41,0-12.03-3.03-13.8-7.36C24.2951,46.47,37.235,49.16,49.7251,46.13Z"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
      />
      <path
        d="M31.6941,32.4036a4.7262,4.7262,0,0,0-8.6382,0"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
      <path
        d="M48.9441,32.4036a4.7262,4.7262,0,0,0-8.6382,0"
        fill="none"
        stroke="#000"
        strokeLinecap="round"
        strokeMiterlimit={10}
        strokeWidth={2}
      />
    </g>
  </svg>
);

export default memo(HappyFaceEmoji);
