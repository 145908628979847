import styled from "@emotion/styled";

const CenteredContent = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CenteredScope = styled.div`
  text-align: center;
`;

const CenteredScopeTopMiddle = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
`;

const TopLeft = styled.div`
  position: absolute;
  top: 1em;
  left: 1em;
`;

const TopRight = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
`;

const BottomRight = styled.div`
  position: absolute;
  bottom: 1em;
  right: 1em;
`;

const BottomLeft = styled.div`
  position: absolute;
  bottom: 1em;
  left: 1em;
`;

export { BottomLeft, BottomRight, CenteredContent, CenteredScope, CenteredScopeTopMiddle, TopLeft, TopRight };
