import React, { memo } from "react";

import { default as GavelIconMUI } from "@mui/icons-material/Gavel";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.gavel.testId;

const GavelIcon = ({ testId, ...props }: Icon) => {
  return <GavelIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(GavelIcon);
