import React, { FC, memo } from "react";

import styled from "@emotion/styled";
import { Stack } from "@mui/material";

import Button from "@/components/Button/Button";
import Typography from "@/components/Typography/Typography";
import content from "@/content";
import { SadFaceEmoji } from "@/icons";
import { CenteredContent } from "@/styles";

const ErrorMessage = styled(Typography)`
  text-align: center;
  font-weight: 700;
  font-size: 1rem;
  max-width: 200px;
`;

const testIdBase = content.components.error.testId;

interface IError {
  errorButtonText: string;
  errorText: string;
  onReloadAction: () => void;
  testId: string;
}

const Error: FC<IError> = ({ errorButtonText, errorText, onReloadAction, testId }) => {
  return (
    <CenteredContent>
      <Stack
        alignItems="center"
        data-testid={`${testIdBase}-${testId}`}
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <SadFaceEmoji height="5rem" testId={`${testIdBase}-emoji-${testId}`} width="5rem" />
        <ErrorMessage testId={`${testIdBase}-message-${testId}`}>{errorText}</ErrorMessage>
        <Button
          onClick={() => onReloadAction()}
          size="large"
          testId={`${testIdBase}-button-${testId}`}
          variant="contained"
        >
          {errorButtonText}
        </Button>
      </Stack>
    </CenteredContent>
  );
};

export { testIdBase };

export default memo(Error);
