import { memo } from "react";

import styled from "@emotion/styled";

interface ComponentWrapperProps {
  marginBottom?: string;
  marginTop?: string;
}

const ComponentWrapper = styled.div<ComponentWrapperProps>`
  margin-top: ${(props) => props.marginTop || "0.5em"};
  margin-bottom: ${(props) => props.marginBottom || "0.5em"};
`;

export default memo(ComponentWrapper);
