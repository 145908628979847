import React, { memo } from "react";

import { Grid } from "@mui/material";

import Button from "@/components/Button/Button";

type TRedirectButtonProfile = {
  label: string;
  redirectLink: string;
  testId: string;
};

const RedirectButtonProfile = ({ label, redirectLink, testId }: TRedirectButtonProfile) => {
  return (
    <Grid item xs={6}>
      <Button fullWidth hrefButton={redirectLink} testId={testId} variant="outlined">
        {label}
      </Button>
    </Grid>
  );
};

export default memo(RedirectButtonProfile);
