import React, { memo } from "react";

import { default as BalanceIconMui } from "@mui/icons-material/Balance";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.add.testId;

const BalanceIcon = ({ testId, ...props }: Icon) => {
  return <BalanceIconMui data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(BalanceIcon);
