import React, { memo } from "react";

import { default as PrivacyTipIconMUI } from "@mui/icons-material/PrivacyTip";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.privacyTip.testId;

const PrivacyTipIcon = ({ testId, ...props }: Icon) => {
  return <PrivacyTipIconMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(PrivacyTipIcon);
