import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import React from "react";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import { Provider } from "react-redux";

import { AuthenticatedRoute, Layout } from "@/containers";
import FirstTimeVisitModal from "@/containers/FirstTimeVisitModal/FirstTimeVisitModal";
import Notifications from "@/containers/Notifications/Notifications";
import { store } from "@/context/store";
import { theme } from "@/styles";

const App = ({ Component, pageProps: { ...pageProps } }: AppProps) => {
  const router = useRouter();

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Layout>
            <FirstTimeVisitModal />
            {pageProps.protected ? (
              <AuthenticatedRoute scope={pageProps.scope}>
                <Component {...pageProps} key={router.asPath} />
              </AuthenticatedRoute>
            ) : (
              <Component {...pageProps} key={router.asPath} />
            )}
          </Layout>
        </LocalizationProvider>
      </ThemeProvider>
      <Notifications />
    </Provider>
  );
};

export default App;
