import React, { memo, useEffect, useState } from "react";

import styled from "@emotion/styled";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { useRouter } from "next/router";

import { IconButtonWrapper } from "@/components";
import NavigationMenuList from "@/containers/NavigationMenuList/NavigationMenuList";
import content from "@/content";
import { MenuIcon } from "@/icons";
import { theme } from "@/styles";

const MenuBarIcon = styled(IconButtonWrapper)`
  position: absolute;
  right: 0.4rem;
  color: white;
`;

const MenuWrapper = styled.div`
  width: 250px;
`;

const MenuHeader = styled.div`
  background-color: ${theme.palette.primary.main};
  height: 48px;
`;

const testIdBase = content.containers.navigationMenu.testId;

const NavigationMenu = () => {
  const [isOpenNavigation, setOpenNavigation] = useState<boolean>(false);

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      setOpenNavigation(false);
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router.events, router]);

  const toggleNavigation = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setOpenNavigation(open);
  };

  return (
    <>
      <MenuBarIcon onClick={toggleNavigation(true)} testId={testIdBase}>
        <MenuIcon fontSize="large" testId={testIdBase} />
      </MenuBarIcon>
      <SwipeableDrawer
        anchor={"right"}
        onClose={toggleNavigation(false)}
        onOpen={toggleNavigation(true)}
        open={isOpenNavigation}
      >
        <MenuWrapper onKeyDown={toggleNavigation(false)}>
          <MenuHeader />
          <NavigationMenuList onDrawerChange={toggleNavigation} router={router} />
        </MenuWrapper>
      </SwipeableDrawer>
    </>
  );
};

export default memo(NavigationMenu);
