import React, { memo } from "react";

import { default as KeyboardArrowDownMUI } from "@mui/icons-material/KeyboardArrowDown";

import content from "@/content";
import { Icon } from "@/icons/type";

const testIdBase = content.icons.keyboardArrowDown.testId;

const KeyboardArrowDownIcon = ({ testId, ...props }: Icon) => {
  return <KeyboardArrowDownMUI data-testid={`${testIdBase}-${testId}`} {...props} />;
};

export default memo(KeyboardArrowDownIcon);
