import React, { memo } from "react";

import ErrorContainer from "@/containers/ErrorContainer/ErrorContainer";
import LoadingContainer from "@/containers/LoadingContainer/LoadingContainer";
import { ComponentChildren } from "@/types";

interface IBase extends ComponentChildren {
  error?: boolean;
  loading?: boolean;
}

const Base = ({ children, error, loading }: IBase) => {
  return <>{error ? <ErrorContainer /> : loading ? <LoadingContainer loading={loading} /> : <>{children}</>}</>;
};

export default memo(Base);
