import {
  BottomLeft,
  BottomRight,
  CenteredContent,
  CenteredScope,
  CenteredScopeTopMiddle,
  TopLeft,
  TopRight,
} from "@/styles/global-style";
import theme from "@/styles/mui-theme/theme";

export { BottomLeft, BottomRight, CenteredContent, CenteredScope, CenteredScopeTopMiddle, theme, TopLeft, TopRight };
